import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	UpdateRequestInputsArray,
	SelectList,
	Steps as DefaultSteps,
	StepsUrbanism
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse as ReactCollapse } from 'react-collapse'
import Collapse from '@components/ui/collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories,
	documentStatusConfig,
	requestSubCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates, CustomDate } from '@services/models'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import * as formUtils from '@pages/auth/__index.utils'
import {
	PHONE_NUMBER_REGEX,
	POSTAL_CODE_REGEX
} from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import Text from '@components/ui/text'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'
import RadioInput from './inputs/radio.input'
import AddressInfoSection from '@components/ui/addressInfosSection'
import Notification from '@components/ui/notification'
import TextAreaInput from './inputs/textarea.inputs'
import CheckboxInput from './inputs/checkbox.input'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'
import SelectInput from './inputs/select.inputs'
import { getAddressByCoordinates } from '@components/ui/addressForm/__index.utils'
import AddressVoiePublicSection from '@components/ui/addressVoiePublicSection'
import MaskedThousandsSeparatorInput from './inputs/textWithThousandsSeparator.inputs'
import { formatNumberWithSpaces } from './__forms.utils'

const ResidentialRenovationOrRepairPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	setDisableNextButton,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onSetCurrentSubStep,
	setStepsList,
	setSteps
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		adresseBlock: string
		h5: string
		greyBackgroundBlock: string
		detailscontainer: string
		subContent: string
		reducedMarginBottom: string
		image: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},

		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},

		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			marginTop: '-15px',
			marginBottom: '15px',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px',
			'& div': {
				alignItems: 'start !important'
			}
		},
		h5: {
			fontSize: '20px',
			fontWeight: '600',
			margin: '15px 0 10px 0'
		},
		greyBackgroundBlock: {
			background: Colors.darkWhite,
			padding: '15px 30px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '50%43%',
			columnGap: '7%',
			[Breakpoints.maxWidth('lg')]: {
				gridTemplateColumns: '45%46%',
				columnGap: '12%'
			},
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important',
					width: '80% !important'
				}
			},
			[Breakpoints.maxWidth('sm')]: {
				'& img': {
					width: '100% !important'
				}
			},
			lineHeight: '1.62em',
			// fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			},
			'& ul': {
				paddingLeft: '18px',
				margin: 0,
				'& li::marker': {
					color: Colors.secondary
				}
			}
		},
		subContent: {
			display: 'grid',
			columnGap: '15px',
			rowGap: '10px',
			gridTemplateColumns: '2fr auto',
			alignItems: 'end',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '2fr auto'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '2fr auto'
			}
		},
		reducedMarginBottom: {
			marginBottom: '18px'
		},
		image: {
			[Breakpoints.maxWidth('md')]: {
				width: '35%'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [showAdresseBlock, setShowAdresseBlock] = useState<boolean>(
		answers?.buildingAddressSameHasApplicant?.toString() == 'false'
			? true
			: false
	)

	const isRenovationInteriorAndExterior = (value) => {
		return (
			value?.toString() ==
			REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
				.request_interiorAndExterior
		)
	}

	const isRenovationExterior = (value) => {
		return (
			value?.toString() ==
			REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
				.request_exterior
		)
	}

	const isRenovationInterior = (value) => {
		return (
			value?.toString() ==
			REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
				.request_interior
		)
	}

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	// ************* Type de demande ***********

	// Que souhaitez-vous faire?
	const [whatToDoInput, setWhatToDoInput] = useState<RequestInputs>({
		name: 'whatToDo',
		label: pageAssets?.request_form_what_would_you_like_to_do,
		labelKey: 'request_form_what_would_you_like_to_do',
		value: answers?.whatToDo?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// ResidentialRenovationOrRepairPermit
	// Rénovation ou réparation intérieure et extérieure
	// Interior and exterior renovation or repair
	const radioListWhatToDo: RadioList[] = [
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_request_interiorAndExterior,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
					.request_interiorAndExterior,
			key: 'request_form_residentialRenovationOrRepairPermit_request_interiorAndExterior'
		},
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_request_exterior,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
					.request_exterior,
			key: 'request_form_residentialRenovationOrRepairPermit_request_exterior'
		},
		{
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_request_interior,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoResidentialRenovationOrRepairPermit
					.request_interior,
			key: 'request_form_residentialRenovationOrRepairPermit_request_interior'
		}
	]

	// Q2 Vous faites cette demande en tant que:
	const [requestAsInput, setRequestAsInput] = useState<RequestInputs>({
		name: 'requestAs',
		label: pageAssets?.request_form_request_as,
		labelKey: 'request_form_request_as',
		value: answers?.requestAs?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	// ************* Informations relatives à l'entreprise ***********

	// Nom de l'entreprise
	const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
		name: 'companyName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers.companyName || '',
		required: [
			REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
		].includes(answers.requestAs?.toString() || ''),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Nom et prénom du représentant
	const [representativeNameInput, setRepresentativeNameInput] =
		useState<RequestInputs>({
			name: 'representativeName',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
			labelKey: 'request_form_swimmingPoolSpaPermit_representativeName_label',
			value: answers.representativeName || '',
			required: [
				REQUEST_CONFIGS_KEYS.requestAs.entreprise,
				REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
			].includes(answers.requestAs?.toString() || ''),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	// Plan d'implantation et d'intégration architecturale (PIIA)

	// Est-ce que les travaux sont assujettis à une approbation PIIA?
	// Is the work subject to PIIA approval?
	const [isSubjectToPiiaApprovalInput, setIsSubjectToPiiaApprovalInput] =
		useState<RequestInputs>({
			name: 'isSubjectToPiiaApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description,
			value: answers.isSubjectToPiiaApproval?.toString() || '',
			required:
				isRenovationExterior(answers?.whatToDo) ||
				isRenovationInteriorAndExterior(answers?.whatToDo),
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Le projet a-t-il reçu une approbation PIIA?
	// Has the project received PIIA approval?
	const [hasBeenSubjectToApprovalInput, setHasBeenSubjectToApprovalInput] =
		useState<RequestInputs>({
			name: 'hasBeenSubjectToApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label',
			value: answers.hasBeenSubjectToApproval?.toString() || '',
			required:
				(isRenovationExterior(answers?.whatToDo) ||
					isRenovationInteriorAndExterior(answers?.whatToDo)) &&
				answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// 	IA number (architectural integration)
	const [iaNumberInput, setIaNumberInput] = useState<RequestInputs>({
		name: 'iaNumber',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_iaNumber_label',
		value: answers.iaNumber || '',
		required:
			(isRenovationExterior(answers?.whatToDo) ||
				isRenovationInteriorAndExterior(answers?.whatToDo)) &&
			answers.hasBeenSubjectToApproval?.toString() == radioListYesNo[0].value &&
			answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Executive Committee (EC) resolution number
	const [ecResolutionNumberInput, setEcResolutionNumberInput] =
		useState<RequestInputs>({
			name: 'ecResolutionNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label',
			value: answers.ecResolutionNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	// hidden input cadastre
	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [isSameLocationInput, setIsSameLocationInput] = useState<RequestInputs>(
		{
			name: 'buildingAddressSameHasApplicant',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSameLocation',
			value: answers?.buildingAddressSameHasApplicant?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const radioListAddressOption: RadioList[] = [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		}
	]

	const [isLocationOwnedInput, setIsLocationOwnedInput] =
		useState<RequestInputs>({
			name: 'isLocationOwned',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
			labelKey: 'request_form_swimmingPoolSpaPermit_isLocationOwned',
			value: answers?.isLocationOwned?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//****** Owner inputs ******

	const [ownerFirstNameInput, setOwnerFirstNameInput] = useState<RequestInputs>(
		{
			name: 'ownerFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.ownerFirstName || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)
	const [ownerLastNameInput, setOwnerLastNameInput] = useState<RequestInputs>({
		name: 'ownerLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers.ownerLastName || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerEmailInput, setOwnerEmailInput] = useState<RequestInputs>({
		name: 'ownerEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.ownerEmail || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerPhoneNumberInput, setOwnerPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'ownerPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.ownerPhoneNumber || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Procuration du propriétaire
	// Owner's power of attorney
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_ownerPowerOfAttorney,
		labelKey: 'request_form_ownerPowerOfAttorney',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		description: '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		category: requestCategories.proxy,
		subCategory: requestSubCategories.subCateg2
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_3 INPUTS -------------------
	//---------------------------------------------------------------

	//  "Quel est l'usage du bâtiment principal existant?"
	// Question 8
	const [mainUseOfExistingBuildingInput, setMainUseOfExistingBuildingInput] =
		useState<RequestInputs>({
			name: 'mainUseOfExistingBuilding',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding',
			value: answers?.mainUseOfExistingBuilding?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListMainUseOfExistingBuilding: RadioList[] =
		pageUtils.radioListMainUseOfExistingBuildingRenovation(pageAssets)

	// Le bâtiment principal existant est-il ou fait-il partie d'une copropriété?
	const [
		isTheNewBuildingPartOfAPropertyInput,
		setIsTheNewBuildingPartOfAPropertyInput
	] = useState<RequestInputs>({
		name: 'existingMainBuildingIsShared',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty',
		value: answers?.existingMainBuildingIsShared?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	// Autorisation des administrateurs
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_adminAutorisation,
		labelKey: 'request_form_buildingDemolitionOrMovingPermit_adminAutorisation',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_4 INPUTS -------------------
	//---------------------------------------------------------------

	//-------------------- Informations sur les rénovations et réparations -----------------------

	// Quel(s) type(s) de travaux souhaitez-vous effectuer?
	// What type of work would you like to do?
	const [typesOfWorkExtInput, setTypesOfWorkExtInput] = useState<RequestInputs>(
		{
			name: 'typesOfWorkExt',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_typesOfWork,
			labelKey: 'request_form_residentialRenovationOrRepairPermit_typesOfWork',
			value: answers?.typesOfWorkExt?.toString() || '',
			required:
				isRenovationExterior(answers?.whatToDo) ||
				isRenovationInteriorAndExterior(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		}
	)

	const radioListTypesOfWorkRenovationExterior: CheckboxList[] =
		pageUtils.radioListTypesOfWorkRenovationExterior(pageAssets)

	// Question 10.1
	// Libellé "Nombre de logements actuels"
	const [numberOfCurrentHousingInput, setNumberOfCurrentHousingInput] =
		useState<RequestInputs>({
			name: 'numberOfCurrentHousing',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfCurrentHousing,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_numberOfCurrentHousing',
			value: answers?.numberOfCurrentHousing?.toString() || '',
			required: [
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.firstOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fifthOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.sixthOption
			].some((option) => answers?.typesOfWorkExt?.toString()?.includes(option)),
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 10.2
	// Libellé "Nombre de logements ajoutés (inscrire 0, si non applicable)"
	const [numberOfHousingAddedInput, setNumberOfHousingAddedInput] =
		useState<RequestInputs>({
			name: 'numberOfHousingAdded',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfHousingAdded,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_numberOfHousingAdded',
			value: answers?.numberOfHousingAdded?.toString() || '',
			required: [
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.firstOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fifthOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.sixthOption
			].some((option) => answers?.typesOfWorkExt?.toString()?.includes(option)),
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 10.3
	// Libellé "Sur quel(s) mur(s) du bâtiment effectuez-vous l'ajout d'ouvertures ou le remplacement de portes et fenêtres?"
	// Choix multiple, plusieurs cases peuvent être sélectionnées simultanément.
	// Case 1 ="Mur avant"
	// Case 2 ="Mur arrière"
	// Case 3 = "Mur gauche"
	// Case 4 = "Mur droit"
	// Retour de la réponse dans RÉso champ ROR34
	const [
		wallWhereAddOpeningsOrReplaceDoorsInput,
		setWallWhereAddOpeningsOrReplaceDoorsInput
	] = useState<RequestInputs>({
		name: 'wallWhereAddOpeningsOrReplaceDoors',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_wallWhereAddOpeningsOrReplaceDoors,
		labelKey:
			'request_form_residentialRenovationOrRepairPermit_wallWhereAddOpeningsOrReplaceDoors',
		value: answers?.wallWhereAddOpeningsOrReplaceDoors?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const checkboxesListWallWhereAddOpeningsOrReplaceDoors: CheckboxList[] =
		pageUtils.checkboxesListWallWhereAddOpeningsOrReplaceDoors(pageAssets)

	// Question 10.4
	// Libellé "Y a-t-il ou y aura-t-il des ouvertures (portes ou fenêtres) situées à moins de 1,5 mètre de la limite du terrain?"
	// Utiliser un bouton radio.
	// Case 1 ="Oui"
	// Case 2 = "Non"
	const [
		isThereOrWillThereBeOpeningsInput,
		setIsThereOrWillThereBeOpeningsInput
	] = useState<RequestInputs>({
		name: 'isThereOrWillThereBeOpenings',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_isThereOrWillThereBeOpenings,
		labelKey:
			'request_form_residentialRenovationOrRepairPermit_isThereOrWillThereBeOpenings',
		value: answers?.isThereOrWillThereBeOpenings?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	// Question 10.5
	// Libellé "Précisions sur le remplacement d’un système de chauffage par un système de géothermie"
	const [
		detailsOnReplacingHeatingSystemWithGeothermalInput,
		setDetailsOnReplacingHeatingSystemWithGeothermalInput
	] = useState<RequestInputs>({
		name: 'detailsOnReplacingHeatingSystemWithGeothermal',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_detailsOnReplacingHeatingSystemWithGeothermal,
		labelKey:
			'request_form_residentialRenovationOrRepairPermit_detailsOnReplacingHeatingSystemWithGeothermal',
		value:
			answers?.detailsOnReplacingHeatingSystemWithGeothermal?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	// Question 10.6
	// Libellé "Matériau existant du revêtement de toiture"
	const [existingRoofingMaterialInput, setExistingRoofingMaterialInput] =
		useState<RequestInputs>({
			name: 'existingRoofingMaterial',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_existingRoofingMaterial,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_existingRoofingMaterial',
			value: answers?.existingRoofingMaterial?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 10.7
	// Libellé "Matériau projeté du revêtement de toiture"
	const [projectedRoofingMaterialInput, setProjectedRoofingMaterialInput] =
		useState<RequestInputs>({
			name: 'projectedRoofingMaterial',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_projectedRoofingMaterial,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_projectedRoofingMaterial',
			value: answers?.projectedRoofingMaterial?.toString() || '',
			required:
				answers?.typesOfWorkExt
					?.toString()
					?.includes(
						REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.ninthOption
					) || false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 10.8
	// Libellé "Matériau existant du revêtement extérieur"
	const [
		existingExteriorCladdingMaterialInput,
		setExistingExteriorCladdingMaterialInput
	] = useState<RequestInputs>({
		name: 'existingExteriorCladdingMaterial',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_existingExteriorCladdingMaterial,
		labelKey:
			'request_form_residentialRenovationOrRepairPermit_existingExteriorCladdingMaterial',
		value: answers?.existingExteriorCladdingMaterial?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	// Question 10.9
	// Libellé "Matériau projeté du revêtement extérieur"
	const [
		projectedExteriorCladdingMaterialInput,
		setProjectedExteriorCladdingMaterialInput
	] = useState<RequestInputs>({
		name: 'projectedExteriorCladdingMaterial',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_projectedExteriorCladdingMaterial,
		labelKey:
			'request_form_residentialRenovationOrRepairPermit_projectedExteriorCladdingMaterial',
		value: answers?.projectedExteriorCladdingMaterial?.toString() || '',
		required:
			answers?.typesOfWorkExt
				?.toString()
				?.includes(
					REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.tenthOption
				) || false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	// Question 10.10
	// Libellé "Précisions sur les réparations à effectuer sur la structure ou la fondation"
	const [
		detailsOnRepairsToBeMadeToStructureOrFoundationInput,
		setDetailsOnRepairsToBeMadeToStructureOrFoundationInput
	] = useState<RequestInputs>({
		name: 'detailsOnRepairsToBeMadeToStructureOrFoundation',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_detailsOnRepairsToBeMadeToStructureOrFoundation,
		labelKey:
			'request_form_residentialRenovationOrRepairPermit_detailsOnRepairsToBeMadeToStructureOrFoundation',
		value:
			answers?.detailsOnRepairsToBeMadeToStructureOrFoundation?.toString() ||
			'',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	// Question 10.11
	// Libellé "Veuillez préciser les autres travaux"
	const [pleaseSpecifyOtherWorkInput, setPleaseSpecifyOtherWorkInput] =
		useState<RequestInputs>({
			name: 'pleaseSpecifyOtherWork',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_pleaseSpecifyOtherWork,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_pleaseSpecifyOtherWork',
			value: answers?.pleaseSpecifyOtherWork?.toString() || '',
			required:
				answers?.typesOfWorkExt
					?.toString()
					?.includes(
						REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fourteenthOption
					) || false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	//----------------------------------------------------------------
	//-------------------- Renovations Internes -------------------

	// typesOfWorkIntInput
	const [typesOfWorkIntInput, setTypesOfWorkIntInput] = useState<RequestInputs>(
		{
			name: 'typesOfWorkInt',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_typesOfWork,
			labelKey: 'request_form_residentialRenovationOrRepairPermit_typesOfWork',
			value: answers?.typesOfWorkInt?.toString() || '',
			required:
				isRenovationInterior(answers?.whatToDo) ||
				isRenovationInteriorAndExterior(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		}
	)

	const radioListTypesOfWorkRenovationInterior: CheckboxList[] =
		pageUtils.radioListTypesOfWorkRenovationInterior(pageAssets)

	// 		Question 11.1
	// Libellé "Nombre de logements actuels"
	const [numberOfCurrentHousingIntInput, setNumberOfCurrentHousingIntInput] =
		useState<RequestInputs>({
			name: 'numberOfCurrentHousingInt',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfCurrentHousing,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_numberOfCurrentHousing',
			value: answers?.numberOfCurrentHousingInt?.toString() || '',
			required:
				answers?.typesOfWorkInt
					?.toString()
					?.includes(
						REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.thirdOption
					) || false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Question 11.2
	// Libellé "Nombre de logements ajoutés (inscrire 0, si non applicable)"
	const [numberOfHousingAddedIntInput, setNumberOfHousingAddedIntInput] =
		useState<RequestInputs>({
			name: 'numberOfHousingAddedInt',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_numberOfHousingAdded,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_numberOfHousingAdded',
			value: answers?.numberOfHousingAddedInt?.toString() || '',
			required:
				answers?.typesOfWorkInt
					?.toString()
					?.includes(
						REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.thirdOption
					) || false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})
	// Question 11.3
	// Libellé "Précisions"
	const [detailsIntInput, setDetailsIntInput] = useState<RequestInputs>({
		name: 'detailsInt',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_detailsInt,
		labelKey: 'request_form_residentialRenovationOrRepairPermit_detailsInt',
		value: answers?.detailsInt?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Question 11.4
	// Libellé "Emplacement de la salle de bain dans le bâtiment"
	const [bathroomLocationInput, setBathroomLocationInput] =
		useState<RequestInputs>({
			name: 'bathroomLocation',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_bathroomLocation,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_bathroomLocation',
			value: answers?.bathroomLocation?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})
	// Question 11.5
	// Libellé "Emplacement de logement dans le bâtiment"
	const [housingLocationInput, setHousingLocationInput] =
		useState<RequestInputs>({
			name: 'housingLocation',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_housingLocation,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_housingLocation',
			value: answers?.housingLocation?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})
	// Question 11.6
	// Libellé "Veuillez préciser les autres travaux"
	const [pleaseSpecifyOtherWorkIntInput, setPleaseSpecifyOtherWorkIntInput] =
		useState<RequestInputs>({
			name: 'pleaseSpecifyOtherWorkInt',
			label:
				pageAssets?.request_form_residentialRenovationOrRepairPermit_pleaseSpecifyOtherWork,
			labelKey:
				'request_form_residentialRenovationOrRepairPermit_pleaseSpecifyOtherWork',
			value: answers?.pleaseSpecifyOtherWorkInt?.toString() || '',
			required:
				answers?.typesOfWorkInt
					?.toString()
					?.includes(
						REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.seventhOption
					) || false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// ----------------------------------------------------------------
	//-------------------- Informations sur les travaux INPUTS ------------------------

	// -------------------  DETAILS_5 -------------------

	// Effectuez-vous ces travaux à la suite d'une catastrophe?
	const [Q24IsWorkDueToDisasterInput, setQ24IsWorkDueToDisasterInput] =
		useState<RequestInputs>({
			name: 'isWorkDueToDisaster',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster',
			value: answers?.isWorkDueToDisaster?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Veuillez préciser WorkDueToDisasterPrecision
	const [
		Q24_1WorkDueToDisasterPrecisionInput,
		setQ24_1WorkDueToDisasterPrecisionInput
	] = useState<RequestInputs>({
		name: 'workDueToDisasterPrecision',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
		value: answers?.workDueToDisasterPrecision?.toString() || '',
		required: Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Coût approximatif des travaux
	const [Q25ApproximateCostOfWorkInput, setQ25ApproximateCostOfWorkInput] =
		useState<RequestInputs>({
			name: 'approximateCostOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork',
			value: answers?.approximateCostOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Est-ce qu'un abattage d'arbres est requis?
	const [Q27IsTreeCuttingRequiredInput, setQ27IsTreeCuttingRequiredInput] =
		useState<RequestInputs>({
			name: 'isTreeCuttingRequired',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired_description,
			value: answers?.isTreeCuttingRequired?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Renseignements supplémentaires
	const [Q28AdditionalInfoInput, setQ28AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ28',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28_description,
			value: answers?.additionalInfoQ28?.toString() || '',
			required: true,
			ref: useRef<HTMLTextAreaElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Date de début (projetée)
	const [Q29StartDateInput, setQ29StartDateInput] = useState<RequestInputs>({
		name: 'workStartDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_startDate',
		value: answers.workStartDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Date de fin (projetée)
	const [Q30EndDateInput, setQ30EndDateInput] = useState<RequestInputs>({
		name: 'workEndDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_endDate',
		value: answers.workEndDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Entrepreneur exécutant les travaux de construction.

	// Qui sera en charge des travaux?
	const [Q31WhoWillBeInChargeOfWorkInput, setQ31WhoWillBeInChargeOfWorkInput] =
		useState<RequestInputs>({
			name: 'whoWillBeInChargeOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork',
			value: answers?.whoWillBeInChargeOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		pageUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	// Je ne connais pas encore l'entrepreneur
	const [Q31_1EntrepreneurUnknownInput, setQ31_1EntrepreneurUnknownInput] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown1',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown1?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom de l'entreprise exécutant les travaux de construction.
	const [
		Q32CompanyNameExecutingWorkInput,
		setQ32CompanyNameExecutingWorkInput
	] = useState<RequestInputs>({
		name: 'companyNameExecutingWork',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
		value: answers.companyNameExecutingWork || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Numéro d'entreprise du Québec (NEQ)
	const [Q33CompanyNEQNumberInput, setQ33CompanyNEQNumberInput] =
		useState<RequestInputs>({
			name: 'companyNEQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNEQNumber',
			value: answers.companyNEQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Numéro de licence RBQ
	const [Q34CompanyRBQNumberInput, setQ34CompanyRBQNumberInput] =
		useState<RequestInputs>({
			name: 'companyRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.companyRBQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [
		Q35CompanyRepresentativeNameInput,
		setQ35CompanyRepresentativeNameInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeName',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
		value: answers.companyRepresentativeName || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Téléphone
	const [
		Q36CompanyRepresentativePhoneNumberInput,
		setQ36CompanyRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.companyRepresentativePhoneNumber || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Courriel
	const [
		Q37CompanyRepresentativeEmailInput,
		setQ37CompanyRepresentativeEmailInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.companyRepresentativeEmail || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Adresse de l'entreprise
	const [Q38CompanyAddressInput, setQ38CompanyAddressInput] =
		useState<RequestInputs>({
			name: 'companyAddress',
			label: pageAssets?.label_address,
			labelKey: 'label_address',
			value: answers.companyAddress || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Ville
	const [Q39CompanyCityInput, setQ39CompanyCityInput] = useState<RequestInputs>(
		{
			name: 'companyCity',
			label: pageAssets?.label_city,
			labelKey: 'label_city',
			value: answers.companyCity || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		}
	)

	// Code postal
	const [Q40CompanyPostalCodeInput, setQ40CompanyPostalCodeInput] =
		useState<RequestInputs>({
			name: 'companyPostalCode',
			label: pageAssets?.label_postalCode,
			labelKey: 'label_postalCode',
			value: answers.companyPostalCode || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	//----------------------------------------------------------------
	//----------------------- DETAILS_6 INPUTS -----------------------
	//----------------------------------------------------------------

	// Est-ce que des travaux de branchement d'aquéduc et d'égouts sont nécessaires à vos travaux ?
	const [
		Q41IsWaterAndSewerConnectionRequiredInput,
		setQ41IsWaterAndSewerConnectionRequiredInput
	] = useState<RequestInputs>({
		name: 'isWaterAndSewerConnectionRequired',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired',
		value: answers.isWaterAndSewerConnectionRequired?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Votre lot est-il branché aux conduites principales municipales suivantes?
	const [
		Q41IsPropertyConnectedToMunicipalConduitsInput,
		setQ41IsPropertyConnectedToMunicipalConduitsInput
	] = useState<RequestInputs>({
		name: 'isPropertyConnectedToMunicipalConduits',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits',
		value: answers.isPropertyConnectedToMunicipalConduits || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	const radioListIsPropertyConnectedToMunicipalConduits: CheckboxList[] =
		pageUtils.radioListIsPropertyConnectedToMunicipalConduits(pageAssets)

	// Veuillez ajouter des précisions, si nécessaire
	const [Q42AdditionalInfoInput, setQ42AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ42',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42',
			value: answers.additionalInfoQ42 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Dans le cadre de vos travaux, prévoyez vous
	// As part of your work, you plan to
	const [Q43PlannedWorkInput, setQ43PlannedWorkInput] = useState<RequestInputs>(
		{
			name: 'plannedWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork,
			labelKey: 'request_form_buildingConstructionOrAdditionPermit_plannedWork',
			value: answers.plannedWork || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		}
	)

	const listPlannedWork: CheckboxList[] = pageUtils.listPlannedWork(pageAssets)

	// Veuillez ajouter des précisions sur les travaux
	const [Q44AdditionalInfoInput, setQ44AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'workTypeBuildingPrecision',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44',
			value: answers.workTypeBuildingPrecision || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Je ne connais pas encore l'entrepreneur
	const [Q45EntrepreneurUnknownInput, setQ45EntrepreneurUnknownInput] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	const isEntrepreneurFieldsRequired = (value) => {
		return [
			listPlannedWork[0].value,
			listPlannedWork[1].value,
			listPlannedWork[2].value,
			listPlannedWork[5].value,
			listPlannedWork[6].value
		].some((option) => value?.toString()?.includes(option))
	}

	// Nom de l'entreprise
	const [Q46EntrepreneurNameInput, setQ46EntrepreneurNameInput] =
		useState<RequestInputs>({
			name: 'entrepreneurName',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
			value: answers.entrepreneurName || '',
			required:
				isEntrepreneurFieldsRequired(answers?.plannedWork) &&
				answers?.entrepreneurUnknown?.toString() == 'false',
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Numéro de licence RBQ
	const [Q47EntrepreneurRBQNumberInput, setQ47EntrepreneurRBQNumberInput] =
		useState<RequestInputs>({
			name: 'entrepreneurRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.entrepreneurRBQNumber || '',
			required:
				isEntrepreneurFieldsRequired(answers?.plannedWork) &&
				answers?.entrepreneurUnknown?.toString() == 'false',
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [
		Q48EntrepreneurRepresentativeNameInput,
		setQ48EntrepreneurRepresentativeNameInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeName',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
		value: answers.entrepreneurRepresentativeName || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Téléphone
	const [
		Q49EntrepreneurRepresentativePhoneNumberInput,
		setQ49EntrepreneurRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.entrepreneurRepresentativePhoneNumber || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Courriel
	const [
		Q50EntrepreneurRepresentativeEmailInput,
		setQ50EntrepreneurRepresentativeEmailInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.entrepreneurRepresentativeEmail || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Adresse
	const [
		Q51EntrepreneurRepresentativeAddressInput,
		setQ51EntrepreneurRepresentativeAddressInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeAddress',
		label: pageAssets?.label_address,
		labelKey: 'label_address',
		value: answers.entrepreneurRepresentativeAddress || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Ville
	const [
		Q52EntrepreneurRepresentativeCityInput,
		setQ52EntrepreneurRepresentativeCityInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeCity',
		label: pageAssets?.label_city,
		labelKey: 'label_city',
		value: answers.entrepreneurRepresentativeCity || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Code postal
	const [
		Q53EntrepreneurRepresentativePostalCodeInput,
		setQ53EntrepreneurRepresentativePostalCodeInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativePostalCode',
		label: pageAssets?.label_postalCode,
		labelKey: 'label_postalCode',
		value: answers.entrepreneurRepresentativePostalCode || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// ----------------------------------------------------------

	const [dummyOptionalInput, setDummyOptionalInput] = useState<RequestInputs>({
		name: 'dummyOptional',
		label: '',
		labelKey: '',
		value: '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})
	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// check if isIA
	// to use value from summary answers or from inputForm.value
	const isPIIACategPermitDocument = (isformInputs?: boolean) => {
		if (isformInputs == true) {
			return isSubjectToPiiaApprovalInput.value == 'true' &&
				hasBeenSubjectToApprovalInput.value == 'true'
				? 'isPIIAApprovalReceived'
				: isSubjectToPiiaApprovalInput.value == 'true' &&
				  hasBeenSubjectToApprovalInput.value == 'false'
				? 'isPIIAApprovalNotReceived'
				: 'isNotPIIA'
		}

		return answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			answers?.hasBeenSubjectToApproval?.toString() == 'true'
			? 'isPIIAApprovalReceived'
			: answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			  answers?.hasBeenSubjectToApproval?.toString() == 'false'
			? 'isPIIAApprovalNotReceived'
			: 'isNotPIIA'
	}

	const getDocumentCategory = (
		type: string,
		category: string,
		isformInputs?: boolean
	) => {
		if (category == '') {
			console.log('category is empty')

			return []
		}

		if (isPIIACategPermitDocument(isformInputs) == 'isNotPIIA') {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isNotPIIA'
			]
		}

		if (isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalReceived') {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isPIIA'
			]['isPIIAApprovalReceived']
		}

		if (
			isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalNotReceived'
		) {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isPIIA'
			]['isPIIAApprovalNotReceived'][category]
		}

		return []
	}

	const getDocumentStatus = (
		type: string,
		category: string | undefined,
		document: string,
		isformInputs?: boolean
	): string => {
		if (!category) {
			return 'not_required'
		}

		const categoryConfig = getDocumentCategory(type, category, isformInputs)

		if (categoryConfig) {
			return categoryConfig[document] || 'not_required'
		}
		return 'not_required'
	}

	const isDocumentToShow = (
		type: string,
		category: string | undefined,
		document: string,
		status: string,
		isformInputs?: boolean
	) => {
		return getDocumentStatus(type, category, document, isformInputs) == status
	}

	// Certificat de localisation
	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_locationCertificate,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_locationCertificate',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required:
			(isRenovationExterior(answers?.whatToDo) ||
				isRenovationInteriorAndExterior(answers?.whatToDo)) &&
			((answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
				answers?.hasBeenSubjectToApproval?.toString() == 'true') ||
				answers?.isSubjectToPiiaApproval?.toString() == 'false'),
		numberMaxFiles: 5,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'architecture
	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_architecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan_PA_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 5,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan mécanique
	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_mechanicalPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_mechanicalPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Certificat de localisation
	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_electricalPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_electricalPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan ventilation
	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label:
			pageAssets?.request_form_residentialRenovationOrRepairPermit_ventilationPlan,
		labelKey:
			'request_form_residentialRenovationOrRepairPermit_ventilationPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'seventhInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan de structure
	const [eighthQuestion, setEighthQuestion] = useState<RequestInputFiles>({
		name: 'eighthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_structuralPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_structuralPlan,',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Fiche Branchement
	const [ninthQuestion, setNinthQuestion] = useState<RequestInputFiles>({
		name: 'ninthInput',
		label: pageAssets?.request_form_buildingEnlargementPermit_connectionSheet,
		labelKey: 'request_form_buildingEnlargementPermit_connectionSheet',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'architecture préliminaire
	const [tenthQuestion, setTenthQuestion] = useState<RequestInputFiles>({
		name: 'tenthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'tenthInput')?.files || []
		),
		required:
			answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			answers?.hasBeenSubjectToApproval?.toString() == 'false',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Fiche Bâtiment/Fiche Technique
	const [eleventhQuestion, setEleventhQuestion] = useState<RequestInputFiles>({
		name: 'eleventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingSheet,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_buildingSheet',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eleventhInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [twelfthQuestion, setTwelfthQuestion] = useState<RequestInputFiles>({
		name: 'twelfthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_otherDocuments',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'twelfthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	// Reusable function to set input and handle errors
	const handleInputChange = (setInput, input, value, inputName) => {
		setInput({
			...input,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, inputName))
	}

	// Reusable hook to update inputs
	const useUpdateInputs = (input) => {
		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, input))
		}, [input])
	}

	const updateInputsRequired = (inputs: any[], value: boolean) => {
		inputs.forEach((input) =>
			input.setState({ ...input.state, required: value })
		)
	}

	const onSetWhatToDoInput = (value: string) => {
		setWhatToDoInput({ ...whatToDoInput, value })

		setTypesOfWorkExtInput({
			...typesOfWorkExtInput,
			required:
				isRenovationExterior(value) || isRenovationInteriorAndExterior(value)
		})

		setTypesOfWorkIntInput({
			...typesOfWorkIntInput,
			required:
				isRenovationInterior(value) || isRenovationInteriorAndExterior(value)
		})

		setIsSubjectToPiiaApprovalInput({
			...isSubjectToPiiaApprovalInput,
			value: '',
			required:
				isRenovationExterior(value) || isRenovationInteriorAndExterior(value)
		})

		onFixError(
			errors.filter(
				(error) =>
					![
						whatToDoInput.name,
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(whatToDoInput)

	useEffect(() => {
		const isPIIA =
			isSubjectToPiiaApprovalInput.value == 'true' &&
			hasBeenSubjectToApprovalInput.value == 'true'
				? 'isPIIAApprovalReceived'
				: isSubjectToPiiaApprovalInput.value == 'true' &&
				  hasBeenSubjectToApprovalInput.value == 'false'
				? 'isPIIAApprovalNotReceived'
				: 'isNotPIIA'

		const IsPIIAApprovalReceived = isPIIA == 'isPIIAApprovalReceived'
		const IsPIIAApprovalNotReceived = isPIIA == 'isPIIAApprovalNotReceived'
		const IsNotPIIA = isPIIA == 'isNotPIIA'

		setThirdQuestion({
			...thirdQuestion,
			required:
				(IsPIIAApprovalReceived || IsNotPIIA) &&
				(isRenovationExterior(whatToDoInput.value) ||
					isRenovationInteriorAndExterior(whatToDoInput.value))
		})

		setTenthQuestion({
			...tenthQuestion,
			required: IsPIIAApprovalNotReceived
		})
	}, [
		whatToDoInput,
		isSubjectToPiiaApprovalInput,
		hasBeenSubjectToApprovalInput
	])
	//------------------------------------------------------------

	const onSetRequestAsInput = (value: string) => {
		setRequestAsInput({ ...requestAsInput, value })

		if ([REQUEST_CONFIGS_KEYS.requestAs.entreprise].includes(value)) {
			setCompanyNameInput({ ...companyNameInput, required: true })
			setRepresentativeNameInput({ ...representativeNameInput, required: true })
		} else {
			setCompanyNameInput({ ...companyNameInput, required: false })
			setRepresentativeNameInput({
				...representativeNameInput,
				required: false
			})
		}

		if (
			value == REQUEST_CONFIGS_KEYS.requestAs.individual &&
			Q31WhoWillBeInChargeOfWorkInput.value ==
				REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.firstOption
		) {
			setQ31WhoWillBeInChargeOfWorkInput({
				...Q31WhoWillBeInChargeOfWorkInput,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						requestAsInput.name,
						companyNameInput.name,
						representativeNameInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(requestAsInput)

	//------------------------------------------------------------

	const onSetCompanyNameInput = (value: string) => {
		handleInputChange(
			setCompanyNameInput,
			companyNameInput,
			value,
			companyNameInput.name
		)
	}

	useUpdateInputs(companyNameInput)

	//------------------------------------------------------------
	const onSetRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setRepresentativeNameInput,
			representativeNameInput,
			value,
			representativeNameInput.name
		)
	}

	useUpdateInputs(representativeNameInput)

	//------------------------------------------------------------

	const onSetIsSubjectToPiiaApprovalInput = (value: string) => {
		setIsSubjectToPiiaApprovalInput({ ...isSubjectToPiiaApprovalInput, value })

		onFixError(
			errors.filter(
				(error) =>
					![
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isSubjectToPiiaApprovalInput)

	useEffect(() => {
		if (
			(isRenovationExterior(whatToDoInput.value) ||
				isRenovationInteriorAndExterior(whatToDoInput.value)) &&
			isSubjectToPiiaApprovalInput.value == radioListYesNo[0].value
		) {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: true
			})
		} else {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: false,
				value: ''
			})
			setIaNumberInput({ ...iaNumberInput, required: false, value: '' })
		}
	}, [isSubjectToPiiaApprovalInput])

	//------------------------------------------------------------

	const onSetHasBeenSubjectToApprovalInput = (value: string) => {
		setHasBeenSubjectToApprovalInput({
			...hasBeenSubjectToApprovalInput,
			value
		})

		onFixError(
			errors.filter(
				(error) =>
					![hasBeenSubjectToApprovalInput.name, iaNumberInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(hasBeenSubjectToApprovalInput)

	useEffect(() => {
		if (
			(isRenovationExterior(whatToDoInput.value) ||
				isRenovationInteriorAndExterior(whatToDoInput.value)) &&
			hasBeenSubjectToApprovalInput.value == radioListYesNo[0].value
		) {
			setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setIaNumberInput({ ...iaNumberInput, required: false })
		}
	}, [hasBeenSubjectToApprovalInput])

	//------------------------------------------------------------

	//------------------------------------------------------------

	const onSetIaNumberInput = (value: string) => {
		handleInputChange(
			setIaNumberInput,
			iaNumberInput,
			value.toLocaleUpperCase(),
			iaNumberInput.name
		)
	}

	useUpdateInputs(iaNumberInput)

	//------------------------------------------------------------

	const onSetEcResolutionNumberInput = (value: string) => {
		handleInputChange(
			setEcResolutionNumberInput,
			ecResolutionNumberInput,
			value.toLocaleUpperCase(),
			ecResolutionNumberInput.name
		)
	}

	useUpdateInputs(ecResolutionNumberInput)

	//------------------------------------------------------------

	//------------------------------------------------------------

	const getCadastralNumber = (coordinates: AddressCoordinates) => {
		getAddressByCoordinates(coordinates).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})
		})
	}

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (coordinates.validate()) {
			setIsAddressOutsideLaval(false)
			getCadastralNumber(coordinates)
		} else {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	// const isValidAddress = () => {
	// 	const coordinates: AddressCoordinates = new AddressCoordinates()
	// 	coordinates.update(authUser?.profile?.address.coordinates!)

	// 	return coordinates.validate()
	// }

	const onSetIsSameLocationInput = (value: string) => {
		setIsSameLocationInput({ ...isSameLocationInput, value })

		setDisableNextButton && setDisableNextButton(true)

		if (value == radioListYesNo[0].value) {
			if (isAddressOutsideLaval) {
				setDisableNextButton && setDisableNextButton(false)
			}
			setTimeout(() => {
				setShowAdresseBlock(false)
			}, 100)
		} else {
			setTimeout(() => {
				setShowAdresseBlock(true)
			}, 100)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isSameLocationInput.name)
		)
	}

	useUpdateInputs(isSameLocationInput)

	useEffect(() => {
		if (
			subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 &&
			isAddressOutsideLaval &&
			isSameLocationInput.value == radioListYesNo[0].value
		) {
			setDisableNextButton && setDisableNextButton(false)
		}
	}, [subSteps?.position])
	//------------------------------------------------------------

	const setRequiredOwnerInfoInputs = (value: boolean) => {
		setOwnerFirstNameInput({ ...ownerFirstNameInput, required: value })
		setOwnerLastNameInput({ ...ownerLastNameInput, required: value })
		setOwnerEmailInput({ ...ownerEmailInput, required: value })
		setOwnerPhoneNumberInput({ ...ownerPhoneNumberInput, required: value })
		setFirstQuestion({ ...firstQuestion, required: value })
	}

	const onSetIsLocationOwnedInput = (value: string) => {
		setIsLocationOwnedInput({ ...isLocationOwnedInput, value })

		if (value == radioListYesNo[1].value) {
			setRequiredOwnerInfoInputs(true)
		} else {
			setRequiredOwnerInfoInputs(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isLocationOwnedInput.name,
						ownerFirstNameInput.name,
						ownerLastNameInput.name,
						ownerEmailInput.name,
						ownerPhoneNumberInput.name,
						firstQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isLocationOwnedInput)
	//------------------------------------------------------------

	const onSetOwnerFirstNameInput = (value: string) => {
		handleInputChange(
			setOwnerFirstNameInput,
			ownerFirstNameInput,
			value,
			ownerFirstNameInput.name
		)
	}

	useUpdateInputs(ownerFirstNameInput)
	//------------------------------------------------------------

	const onSetOwnerLastNameInput = (value: string) => {
		handleInputChange(
			setOwnerLastNameInput,
			ownerLastNameInput,
			value,
			ownerLastNameInput.name
		)
	}

	useUpdateInputs(ownerLastNameInput)

	//------------------------------------------------------------

	const onSetOwnerEmailInput = (value: string) => {
		handleInputChange(
			setOwnerEmailInput,
			ownerEmailInput,
			value,
			ownerEmailInput.name
		)
	}

	useUpdateInputs(ownerEmailInput)
	//------------------------------------------------------------

	const onSetOwnerPhoneNumberInput = (value: string) => {
		handleInputChange(
			setOwnerPhoneNumberInput,
			ownerPhoneNumberInput,
			value,
			ownerPhoneNumberInput.name
		)
	}

	useUpdateInputs(ownerPhoneNumberInput)
	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 3 ---------------------------
	// ----------------------------------------------------------------

	const onSetMainUseOfExistingBuildingInput = (value: string) => {
		setMainUseOfExistingBuildingInput({
			...mainUseOfExistingBuildingInput,
			value
		})

		onFixError(
			errors.filter(
				(error) =>
					![
						mainUseOfExistingBuildingInput.name
						// Q10_1PAExistingMainBuildingHasHousingInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(mainUseOfExistingBuildingInput)

	//------------------------------------------------------------

	const onSetIsTheNewBuildingPartOfAPropertyInput = (value: string) => {
		setIsTheNewBuildingPartOfAPropertyInput({
			...isTheNewBuildingPartOfAPropertyInput,
			value
		})

		// if (value == radioListYesNo[0].value) {
		// 	setSecondQuestion({ ...secondQuestion, required: true })
		// } else {
		// 	setSecondQuestion({ ...secondQuestion, required: false })
		// }

		onFixError(
			errors.filter(
				(error) =>
					![
						isTheNewBuildingPartOfAPropertyInput.name,
						secondQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isTheNewBuildingPartOfAPropertyInput)

	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////

	//------------------------------------------------------------

	const onSetTypesOfWorkExtInput = (value: string) => {
		let newValue
		if (typesOfWorkExtInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				typesOfWorkExtInput.value
			)
		} else {
			newValue =
				typesOfWorkExtInput.value == ''
					? value
					: typesOfWorkExtInput.value + `, ${value}`
		}

		setTypesOfWorkExtInput({
			...typesOfWorkExtInput,
			value: newValue
		})

		onFixError(
			errors.filter(
				(error) =>
					![
						typesOfWorkExtInput.name,
						numberOfCurrentHousingInput.name,
						numberOfHousingAddedInput.name,
						projectedRoofingMaterialInput.name,
						projectedExteriorCladdingMaterialInput.name,
						pleaseSpecifyOtherWorkInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(typesOfWorkExtInput)

	useEffect(() => {
		// Q10.1 numberOfCurrentHousingInput
		setNumberOfCurrentHousingInput({
			...numberOfCurrentHousingInput,
			required: [
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.firstOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fifthOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.sixthOption
			].some((option) => typesOfWorkExtInput.value?.includes(option))
		})

		// Q10.2 numberOfHousingAddedInput
		setNumberOfHousingAddedInput({
			...numberOfHousingAddedInput,
			required: [
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.firstOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fifthOption,
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.sixthOption
			].some((option) => typesOfWorkExtInput.value?.includes(option))
		})

		// Q10.7 projectedRoofingMaterialInput
		setProjectedRoofingMaterialInput({
			...projectedRoofingMaterialInput,
			required: typesOfWorkExtInput.value?.includes(
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.ninthOption
			)
		})

		// Q10.9 projectedExteriorCladdingMaterialInput
		setProjectedExteriorCladdingMaterialInput({
			...projectedExteriorCladdingMaterialInput,
			required: typesOfWorkExtInput.value?.includes(
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.tenthOption
			)
		})

		// Q10.11 pleaseSpecifyOtherWorkInput
		setPleaseSpecifyOtherWorkInput({
			...pleaseSpecifyOtherWorkInput,
			required: typesOfWorkExtInput.value?.includes(
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.fourteenthOption
			)
		})
	}, [typesOfWorkExtInput])

	//------------------------------------------------------------

	// onSetNumberOfCurrentHousingInput
	const onSetNumberOfCurrentHousingInput = (value: string) => {
		handleInputChange(
			setNumberOfCurrentHousingInput,
			numberOfCurrentHousingInput,
			value,
			numberOfCurrentHousingInput.name
		)
	}

	useUpdateInputs(numberOfCurrentHousingInput)

	// ------------------------------------------------------------

	const onSetNumberOfHousingAddedInput = (value: string) => {
		handleInputChange(
			setNumberOfHousingAddedInput,
			numberOfHousingAddedInput,
			value,
			numberOfHousingAddedInput.name
		)
	}

	useUpdateInputs(numberOfHousingAddedInput)

	// ------------------------------------------------------------
	const onSetWallWhereAddOpeningsOrReplaceDoorsInput = (value: string) => {
		let newValue
		if (wallWhereAddOpeningsOrReplaceDoorsInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				wallWhereAddOpeningsOrReplaceDoorsInput.value
			)
		} else {
			newValue =
				wallWhereAddOpeningsOrReplaceDoorsInput.value == ''
					? value
					: wallWhereAddOpeningsOrReplaceDoorsInput.value + `, ${value}`
		}

		setWallWhereAddOpeningsOrReplaceDoorsInput({
			...wallWhereAddOpeningsOrReplaceDoorsInput,
			value: newValue
		})

		onFixError(
			errors.filter(
				(error) =>
					![wallWhereAddOpeningsOrReplaceDoorsInput.name].includes(error)
			)
		)
	}

	useUpdateInputs(wallWhereAddOpeningsOrReplaceDoorsInput)

	// ------------------------------------------------------------
	const onSetIsThereOrWillThereBeOpeningsInput = (value: string) => {
		handleInputChange(
			setIsThereOrWillThereBeOpeningsInput,
			isThereOrWillThereBeOpeningsInput,
			value,
			isThereOrWillThereBeOpeningsInput.name
		)
	}

	useUpdateInputs(isThereOrWillThereBeOpeningsInput)

	// ------------------------------------------------------------
	const onSetDetailsOnReplacingHeatingSystemWithGeothermalInput = (
		value: string
	) => {
		handleInputChange(
			setDetailsOnReplacingHeatingSystemWithGeothermalInput,
			detailsOnReplacingHeatingSystemWithGeothermalInput,
			value,
			detailsOnReplacingHeatingSystemWithGeothermalInput.name
		)
	}

	useUpdateInputs(detailsOnReplacingHeatingSystemWithGeothermalInput)

	// ------------------------------------------------------------

	const onSetExistingRoofingMaterialInput = (value: string) => {
		handleInputChange(
			setExistingRoofingMaterialInput,
			existingRoofingMaterialInput,
			value,
			existingRoofingMaterialInput.name
		)
	}

	useUpdateInputs(existingRoofingMaterialInput)

	// ------------------------------------------------------------
	const onSetProjectedRoofingMaterialInput = (value: string) => {
		handleInputChange(
			setProjectedRoofingMaterialInput,
			projectedRoofingMaterialInput,
			value,
			projectedRoofingMaterialInput.name
		)
	}

	useUpdateInputs(projectedRoofingMaterialInput)

	// ------------------------------------------------------------
	const onSetExistingExteriorCladdingMaterialInput = (value: string) => {
		handleInputChange(
			setExistingExteriorCladdingMaterialInput,
			existingExteriorCladdingMaterialInput,
			value,
			existingExteriorCladdingMaterialInput.name
		)
	}

	useUpdateInputs(existingExteriorCladdingMaterialInput)

	// ------------------------------------------------------------
	const onSetProjectedExteriorCladdingMaterialInput = (value: string) => {
		handleInputChange(
			setProjectedExteriorCladdingMaterialInput,
			projectedExteriorCladdingMaterialInput,
			value,
			projectedExteriorCladdingMaterialInput.name
		)
	}

	useUpdateInputs(projectedExteriorCladdingMaterialInput)

	// ------------------------------------------------------------
	const onSetDetailsOnRepairsToBeMadeToStructureOrFoundationInput = (
		value: string
	) => {
		handleInputChange(
			setDetailsOnRepairsToBeMadeToStructureOrFoundationInput,
			detailsOnRepairsToBeMadeToStructureOrFoundationInput,
			value,
			detailsOnRepairsToBeMadeToStructureOrFoundationInput.name
		)
	}

	useUpdateInputs(detailsOnRepairsToBeMadeToStructureOrFoundationInput)

	// ------------------------------------------------------------
	const onSetPleaseSpecifyOtherWorkInput = (value: string) => {
		handleInputChange(
			setPleaseSpecifyOtherWorkInput,
			pleaseSpecifyOtherWorkInput,
			value,
			pleaseSpecifyOtherWorkInput.name
		)
	}

	useUpdateInputs(pleaseSpecifyOtherWorkInput)

	// ------------------------------------------------------------
	// ------------------------------------------------------------
	// ------------------------------------------------------------

	const onSetTypesOfWorkIntInput = (value: string) => {
		let newValue
		if (typesOfWorkIntInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				typesOfWorkIntInput.value
			)
		} else {
			newValue =
				typesOfWorkIntInput.value == ''
					? value
					: typesOfWorkIntInput.value + `, ${value}`
		}

		setTypesOfWorkIntInput({
			...typesOfWorkIntInput,
			value: newValue
		})

		onFixError(
			errors.filter(
				(error) =>
					![
						typesOfWorkIntInput.name,
						numberOfCurrentHousingIntInput.name,
						numberOfHousingAddedIntInput.name,
						pleaseSpecifyOtherWorkIntInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(typesOfWorkIntInput)

	useEffect(() => {
		setNumberOfCurrentHousingIntInput({
			...numberOfCurrentHousingIntInput,
			required: typesOfWorkIntInput.value?.includes(
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.thirdOption
			)
		})

		setNumberOfHousingAddedIntInput({
			...numberOfHousingAddedIntInput,
			required: typesOfWorkIntInput.value?.includes(
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.thirdOption
			)
		})

		setPleaseSpecifyOtherWorkIntInput({
			...pleaseSpecifyOtherWorkIntInput,
			required: typesOfWorkIntInput.value?.includes(
				REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.seventhOption
			)
		})
	}, [typesOfWorkIntInput])

	// ------------------------------------------------------------

	const onSetNumberOfCurrentHousingIntInput = (value: string) => {
		handleInputChange(
			setNumberOfCurrentHousingIntInput,
			numberOfCurrentHousingIntInput,
			value,
			numberOfCurrentHousingIntInput.name
		)
	}

	useUpdateInputs(numberOfCurrentHousingIntInput)

	// ------------------------------------------------------------

	const onSetNumberOfHousingAddedIntInput = (value: string) => {
		handleInputChange(
			setNumberOfHousingAddedIntInput,
			numberOfHousingAddedIntInput,
			value,
			numberOfHousingAddedIntInput.name
		)
	}

	useUpdateInputs(numberOfHousingAddedIntInput)

	// ------------------------------------------------------------

	const onSetDetailsIntInput = (value: string) => {
		handleInputChange(
			setDetailsIntInput,
			detailsIntInput,
			value,
			detailsIntInput.name
		)
	}

	useUpdateInputs(detailsIntInput)

	// ------------------------------------------------------------

	const onSetBathroomLocationInput = (value: string) => {
		handleInputChange(
			setBathroomLocationInput,
			bathroomLocationInput,
			value,
			bathroomLocationInput.name
		)
	}

	useUpdateInputs(bathroomLocationInput)

	// ------------------------------------------------------------

	const onSetHousingLocationInput = (value: string) => {
		handleInputChange(
			setHousingLocationInput,
			housingLocationInput,
			value,
			housingLocationInput.name
		)
	}

	useUpdateInputs(housingLocationInput)

	// ------------------------------------------------------------

	const onSetPleaseSpecifyOtherWorkIntInput = (value: string) => {
		handleInputChange(
			setPleaseSpecifyOtherWorkIntInput,
			pleaseSpecifyOtherWorkIntInput,
			value,
			pleaseSpecifyOtherWorkIntInput.name
		)
	}

	useUpdateInputs(pleaseSpecifyOtherWorkIntInput)

	// ----------------------------------------------------------------
	// -------------------------  Substep 4 ---------------------------
	// ----------------------------------------------------------------

	//------------------------------------------------------------

	const onSetQ24IsWorkDueToDisasterInput = (value: string) => {
		setQ24IsWorkDueToDisasterInput({
			...Q24IsWorkDueToDisasterInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: true
			})
		} else {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: false,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q24IsWorkDueToDisasterInput.name,
						Q24_1WorkDueToDisasterPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q24IsWorkDueToDisasterInput)

	//------------------------------------------------------------

	const onSetQ24_1WorkDueToDisasterPrecisionInput = (value: string) => {
		handleInputChange(
			setQ24_1WorkDueToDisasterPrecisionInput,
			Q24_1WorkDueToDisasterPrecisionInput,
			value,
			Q24_1WorkDueToDisasterPrecisionInput.name
		)
	}

	useUpdateInputs(Q24_1WorkDueToDisasterPrecisionInput)

	//------------------------------------------------------------

	const onSetQ25ApproximateCostOfWorkInput = (value) => {
		// const rawValue = value?.toString().replace(/ /g, '')
		handleInputChange(
			setQ25ApproximateCostOfWorkInput,
			Q25ApproximateCostOfWorkInput,
			value,
			Q25ApproximateCostOfWorkInput.name
		)
	}

	useUpdateInputs(Q25ApproximateCostOfWorkInput)

	//------------------------------------------------------------

	//------------------------------------------------------------

	const onSetQ27IsTreeCuttingRequiredInput = (value: string) => {
		handleInputChange(
			setQ27IsTreeCuttingRequiredInput,
			Q27IsTreeCuttingRequiredInput,
			value,
			Q27IsTreeCuttingRequiredInput.name
		)
	}

	useUpdateInputs(Q27IsTreeCuttingRequiredInput)

	//------------------------------------------------------------

	const onSetQ28AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ28AdditionalInfoInput,
			Q28AdditionalInfoInput,
			value,
			Q28AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q28AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetQ29StartDateInput = (value: string) => {
		handleInputChange(
			setQ29StartDateInput,
			Q29StartDateInput,
			value,
			Q29StartDateInput.name
		)
	}

	useUpdateInputs(Q29StartDateInput)

	//------------------------------------------------------------

	const onSetQ30EndDateInput = (value: string) => {
		handleInputChange(
			setQ30EndDateInput,
			Q30EndDateInput,
			value,
			Q30EndDateInput.name
		)
	}

	useUpdateInputs(Q30EndDateInput)

	//------------------------------------------------------------

	const setCompanyInputsRequired = (value: boolean) => {
		setQ32CompanyNameExecutingWorkInput({
			...Q32CompanyNameExecutingWorkInput,
			required: value
		})
		setQ33CompanyNEQNumberInput({
			...Q33CompanyNEQNumberInput,
			required: value
		})
		setQ34CompanyRBQNumberInput({
			...Q34CompanyRBQNumberInput,
			required: value
		})
		setQ35CompanyRepresentativeNameInput({
			...Q35CompanyRepresentativeNameInput,
			required: value
		})
		setQ36CompanyRepresentativePhoneNumberInput({
			...Q36CompanyRepresentativePhoneNumberInput,
			required: value
		})
		setQ37CompanyRepresentativeEmailInput({
			...Q37CompanyRepresentativeEmailInput,
			required: value
		})
		setQ38CompanyAddressInput({
			...Q38CompanyAddressInput,
			required: value
		})
		setQ39CompanyCityInput({
			...Q39CompanyCityInput,
			required: value
		})
		setQ40CompanyPostalCodeInput({
			...Q40CompanyPostalCodeInput,
			required: value
		})
	}

	const onSetQ31WhoWillBeInChargeOfWorkInput = (value: string) => {
		setQ31WhoWillBeInChargeOfWorkInput({
			...Q31WhoWillBeInChargeOfWorkInput,
			value
		})

		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value: 'false'
		})

		if (value == radioListWhoWillBeInChargeOfWork[0].value) {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q31WhoWillBeInChargeOfWorkInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31WhoWillBeInChargeOfWorkInput)

	// --------------------------------------------------------------------------

	const onSetQ31_1EntrepreneurUnknownInput = (value: string) => {
		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value
		})

		if (value?.toString() == 'true') {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31_1EntrepreneurUnknownInput)

	//------------------------------------------------------------

	const onSetQ32CompanyNameExecutingWorkInput = (value: string) => {
		handleInputChange(
			setQ32CompanyNameExecutingWorkInput,
			Q32CompanyNameExecutingWorkInput,
			value,
			Q32CompanyNameExecutingWorkInput.name
		)
	}

	useUpdateInputs(Q32CompanyNameExecutingWorkInput)

	//------------------------------------------------------------

	const onSetQ33CompanyNEQNumberInput = (value: string) => {
		handleInputChange(
			setQ33CompanyNEQNumberInput,
			Q33CompanyNEQNumberInput,
			value,
			Q33CompanyNEQNumberInput.name
		)
	}

	useUpdateInputs(Q33CompanyNEQNumberInput)
	//------------------------------------------------------------

	const onSetQ34CompanyRBQNumberInput = (value: string) => {
		handleInputChange(
			setQ34CompanyRBQNumberInput,
			Q34CompanyRBQNumberInput,
			value,
			Q34CompanyRBQNumberInput.name
		)
	}

	useUpdateInputs(Q34CompanyRBQNumberInput)
	//------------------------------------------------------------

	const onSetQ35CompanyRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setQ35CompanyRepresentativeNameInput,
			Q35CompanyRepresentativeNameInput,
			value,
			Q35CompanyRepresentativeNameInput.name
		)
	}

	useUpdateInputs(Q35CompanyRepresentativeNameInput)
	//------------------------------------------------------------
	const onSetQ36CompanyRepresentativePhoneNumberInput = (value: string) => {
		handleInputChange(
			setQ36CompanyRepresentativePhoneNumberInput,
			Q36CompanyRepresentativePhoneNumberInput,
			value,
			Q36CompanyRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(Q36CompanyRepresentativePhoneNumberInput)
	//------------------------------------------------------------

	const onSetQ37CompanyRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setQ37CompanyRepresentativeEmailInput,
			Q37CompanyRepresentativeEmailInput,
			value,
			Q37CompanyRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(Q37CompanyRepresentativeEmailInput)
	//------------------------------------------------------------

	const onSetQ38CompanyAddressInput = (value: string) => {
		handleInputChange(
			setQ38CompanyAddressInput,
			Q38CompanyAddressInput,
			value,
			Q38CompanyAddressInput.name
		)
	}

	useUpdateInputs(Q38CompanyAddressInput)
	//------------------------------------------------------------

	const onSetQ39CompanyCityInput = (value: string) => {
		handleInputChange(
			setQ39CompanyCityInput,
			Q39CompanyCityInput,
			value,
			Q39CompanyCityInput.name
		)
	}

	useUpdateInputs(Q39CompanyCityInput)
	//------------------------------------------------------------
	const onSetQ40CompanyPostalCodeInput = (value: string) => {
		handleInputChange(
			setQ40CompanyPostalCodeInput,
			Q40CompanyPostalCodeInput,
			value?.toUpperCase(),
			Q40CompanyPostalCodeInput.name
		)
	}

	useUpdateInputs(Q40CompanyPostalCodeInput)
	//------------------------------------------------------------

	const onSetQ41IsWaterAndSewerConnectionRequiredInput = (value: string) => {
		setQ41IsWaterAndSewerConnectionRequiredInput({
			...Q41IsWaterAndSewerConnectionRequiredInput,
			value: value
		})

		if (value == radioListYesNo[1].value) {
			setQ41IsPropertyConnectedToMunicipalConduitsInput({
				...Q41IsPropertyConnectedToMunicipalConduitsInput,
				value: ''
			})
			setQ43PlannedWorkInput({
				...Q43PlannedWorkInput,
				value: ''
			})
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: false
			})
			setQ45EntrepreneurUnknownInput({
				...Q45EntrepreneurUnknownInput,
				value: 'false'
			})
			setEntrepreneurFieldsRequired(false)

			setDisabledMunicipalConduits([])
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q41IsWaterAndSewerConnectionRequiredInput.name,
						Q42AdditionalInfoInput.name,
						Q44AdditionalInfoInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q41IsWaterAndSewerConnectionRequiredInput)

	//------------------------------------------------------------

	const [disabledMunicipalConduits, setDisabledMunicipalConduits] = useState<
		string[]
	>([])

	const onSetQ41IsPropertyConnectedToMunicipalConduitsInput = (
		value: string
	) => {
		let newValue
		if (Q41IsPropertyConnectedToMunicipalConduitsInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				Q41IsPropertyConnectedToMunicipalConduitsInput.value
			)
		} else {
			newValue =
				Q41IsPropertyConnectedToMunicipalConduitsInput.value == ''
					? value
					: Q41IsPropertyConnectedToMunicipalConduitsInput.value + `, ${value}`
		}

		setQ41IsPropertyConnectedToMunicipalConduitsInput({
			...Q41IsPropertyConnectedToMunicipalConduitsInput,
			value: newValue
		})

		// Disable the value of the radio button that is not selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value !==
			value
		) {
			setDisabledMunicipalConduits([
				radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value
			])
		}
		// Disable the value of the radio button that is selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value ==
			value
		) {
			setDisabledMunicipalConduits(
				radioListIsPropertyConnectedToMunicipalConduits
					.slice(0, 4)
					.map((item) => item.value)
			)
		}
		// Enable the value of the radio button if the value is empty
		if (newValue == '') {
			setDisabledMunicipalConduits([])
		}

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				Q41IsPropertyConnectedToMunicipalConduitsInput.name
			)
		)
	}

	useUpdateInputs(Q41IsPropertyConnectedToMunicipalConduitsInput)

	//-------------------------------------------------------------

	const onSetQ42AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ42AdditionalInfoInput,
			Q42AdditionalInfoInput,
			value,
			Q42AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q42AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetQ43PlannedWorkInput = (value: string) => {
		let newValue
		if (Q43PlannedWorkInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				Q43PlannedWorkInput.value
			)
		} else {
			newValue =
				Q43PlannedWorkInput.value == ''
					? value
					: Q43PlannedWorkInput.value + `, ${value}`
		}

		setQ43PlannedWorkInput({
			...Q43PlannedWorkInput,
			value: newValue
		})

		if (newValue.includes(listPlannedWork.slice(-1)[0].value)) {
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: true
			})
		} else {
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: false
			})
		}

		if (isEntrepreneurFieldsRequired(newValue)) {
			if (Q45EntrepreneurUnknownInput.value?.toString() == 'true') {
				setEntrepreneurFieldsRequired(false)
			} else {
				setEntrepreneurFieldsRequired(true)
			}
		} else {
			setEntrepreneurFieldsRequired(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q43PlannedWorkInput.name,
						Q44AdditionalInfoInput.name,
						Q45EntrepreneurUnknownInput.name,
						Q46EntrepreneurNameInput.name,
						Q47EntrepreneurRBQNumberInput.name,
						Q48EntrepreneurRepresentativeNameInput.name,
						Q49EntrepreneurRepresentativePhoneNumberInput.name,
						Q50EntrepreneurRepresentativeEmailInput.name,
						Q51EntrepreneurRepresentativeAddressInput.name,
						Q52EntrepreneurRepresentativeCityInput.name,
						Q53EntrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q43PlannedWorkInput)
	// --------------------------------------------------------------------------

	const onSetQ44AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ44AdditionalInfoInput,
			Q44AdditionalInfoInput,
			value,
			Q44AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q44AdditionalInfoInput)

	// --------------------------------------------------------------------------
	// --------------------------------------------------------------------------

	const setEntrepreneurFieldsRequired = (value) => {
		setQ46EntrepreneurNameInput({
			...Q46EntrepreneurNameInput,
			required: value
		})
		setQ47EntrepreneurRBQNumberInput({
			...Q47EntrepreneurRBQNumberInput,
			required: value
		})
		setQ48EntrepreneurRepresentativeNameInput({
			...Q48EntrepreneurRepresentativeNameInput,
			required: value
		})
		setQ49EntrepreneurRepresentativePhoneNumberInput({
			...Q49EntrepreneurRepresentativePhoneNumberInput,
			required: value
		})
		setQ50EntrepreneurRepresentativeEmailInput({
			...Q50EntrepreneurRepresentativeEmailInput,
			required: value
		})
		setQ51EntrepreneurRepresentativeAddressInput({
			...Q51EntrepreneurRepresentativeAddressInput,
			required: value
		})
		setQ52EntrepreneurRepresentativeCityInput({
			...Q52EntrepreneurRepresentativeCityInput,
			required: value
		})
		setQ53EntrepreneurRepresentativePostalCodeInput({
			...Q53EntrepreneurRepresentativePostalCodeInput,
			required: value
		})
	}

	const onSetQ45EntrepreneurUnknownInput = (value: string) => {
		setQ45EntrepreneurUnknownInput({
			...Q45EntrepreneurUnknownInput,
			value
		})

		if (
			isEntrepreneurFieldsRequired(Q43PlannedWorkInput.value) &&
			value.toString() == 'true'
		) {
			setEntrepreneurFieldsRequired(false)
		} else {
			setEntrepreneurFieldsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q45EntrepreneurUnknownInput.name,
						Q46EntrepreneurNameInput.name,
						Q47EntrepreneurRBQNumberInput.name,
						Q48EntrepreneurRepresentativeNameInput.name,
						Q49EntrepreneurRepresentativePhoneNumberInput.name,
						Q50EntrepreneurRepresentativeEmailInput.name,
						Q51EntrepreneurRepresentativeAddressInput.name,
						Q52EntrepreneurRepresentativeCityInput.name,
						Q53EntrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q45EntrepreneurUnknownInput)
	// --------------------------------------------------------------------------

	const onSetQ46EntrepreneurNameInput = (value: string) => {
		handleInputChange(
			setQ46EntrepreneurNameInput,
			Q46EntrepreneurNameInput,
			value,
			Q46EntrepreneurNameInput.name
		)
	}

	useUpdateInputs(Q46EntrepreneurNameInput)

	// --------------------------------------------------------------------------

	const onSetQ47EntrepreneurRBQNumberInput = (value: string) => {
		handleInputChange(
			setQ47EntrepreneurRBQNumberInput,
			Q47EntrepreneurRBQNumberInput,
			value,
			Q47EntrepreneurRBQNumberInput.name
		)
	}

	useUpdateInputs(Q47EntrepreneurRBQNumberInput)

	// --------------------------------------------------------------------------

	const onSetQ48EntrepreneurRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setQ48EntrepreneurRepresentativeNameInput,
			Q48EntrepreneurRepresentativeNameInput,
			value,
			Q48EntrepreneurRepresentativeNameInput.name
		)
	}

	useUpdateInputs(Q48EntrepreneurRepresentativeNameInput)

	// --------------------------------------------------------------------------

	const onSetQ49EntrepreneurRepresentativePhoneNumberInput = (
		value: string
	) => {
		handleInputChange(
			setQ49EntrepreneurRepresentativePhoneNumberInput,
			Q49EntrepreneurRepresentativePhoneNumberInput,
			value,
			Q49EntrepreneurRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(Q49EntrepreneurRepresentativePhoneNumberInput)

	// --------------------------------------------------------------------------

	const onSetQ50EntrepreneurRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setQ50EntrepreneurRepresentativeEmailInput,
			Q50EntrepreneurRepresentativeEmailInput,
			value,
			Q50EntrepreneurRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(Q50EntrepreneurRepresentativeEmailInput)

	// --------------------------------------------------------------------------

	const onSetQ51EntrepreneurRepresentativeAddressInput = (value: string) => {
		handleInputChange(
			setQ51EntrepreneurRepresentativeAddressInput,
			Q51EntrepreneurRepresentativeAddressInput,
			value,
			Q51EntrepreneurRepresentativeAddressInput.name
		)
	}

	useUpdateInputs(Q51EntrepreneurRepresentativeAddressInput)

	// --------------------------------------------------------------------------

	const onSetQ52EntrepreneurRepresentativeCityInput = (value: string) => {
		handleInputChange(
			setQ52EntrepreneurRepresentativeCityInput,
			Q52EntrepreneurRepresentativeCityInput,
			value,
			Q52EntrepreneurRepresentativeCityInput.name
		)
	}

	useUpdateInputs(Q52EntrepreneurRepresentativeCityInput)

	// --------------------------------------------------------------------------

	const onSetQ53EntrepreneurRepresentativePostalCodeInput = (value: string) => {
		handleInputChange(
			setQ53EntrepreneurRepresentativePostalCodeInput,
			Q53EntrepreneurRepresentativePostalCodeInput,
			value?.toUpperCase(),
			Q53EntrepreneurRepresentativePostalCodeInput.name
		)
	}

	useUpdateInputs(Q53EntrepreneurRepresentativePostalCodeInput)

	// --------------------------------------------------------------------------
	// --------------------------------------------------------------------------

	//------------------------------------------------------------

	// --------------------------------------------------------------------------

	//////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, dummyOptionalInput))
	}, [dummyOptionalInput])

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// DOCUMENTS
	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	const updateQuestionFiles = (setQuestion, question, files) => {
		setQuestion({
			...question,
			files
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, question.name))
	}

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFirstQuestion, firstQuestion, files)
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSecondQuestion, secondQuestion, files)
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirdQuestion, thirdQuestion, files)
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourthQuestion, fourthQuestion, files)
	}

	const onSelectFileFifthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFifthQuestion, fifthQuestion, files)
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSixthQuestion, sixthQuestion, files)
	}

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSeventhQuestion, seventhQuestion, files)
	}

	const onSelectFileEighthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEighthQuestion, eighthQuestion, files)
	}

	const onSelectFileNinthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setNinthQuestion, ninthQuestion, files)
	}

	const onSelectFileTenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTenthQuestion, tenthQuestion, files)
	}

	const onSelectFileEleventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEleventhQuestion, eleventhQuestion, files)
	}

	const onSelectFileTwelfthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTwelfthQuestion, twelfthQuestion, files)
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES
	////////////////////////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthQuestion))
	}, [eighthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthQuestion))
	}, [ninthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, tenthQuestion))
	}, [tenthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eleventhQuestion))
	}, [eleventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, twelfthQuestion))
	}, [twelfthQuestion])

	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES FROM STORE

	// Helper function to set question files
	const setQuestionFiles = (inputName: string, question, setQuestion) => {
		if (!!answers[inputName] && question.files.size == 0) {
			getFilesFromStore(inputName, answers).then((result: Set<File>) => {
				setQuestion({
					...question,
					files: result
				})
			})
		}
	}

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		setQuestionFiles('firstInput', firstQuestion, setFirstQuestion)

		// secondInput input
		setQuestionFiles('secondInput', secondQuestion, setSecondQuestion)

		// thirdInput input
		setQuestionFiles('thirdInput', thirdQuestion, setThirdQuestion)

		// fouthInput input
		setQuestionFiles('fourthInput', fourthQuestion, setFourthQuestion)

		// fifthInput input
		setQuestionFiles('fifthInput', fifthQuestion, setFifthQuestion)

		// sixthQuestion input
		setQuestionFiles('sixthInput', sixthQuestion, setSixthQuestion)

		// seventhQuestion input
		setQuestionFiles('seventhInput', seventhQuestion, setSeventhQuestion)

		// eighthQuestion input
		setQuestionFiles('eighthInput', eighthQuestion, setEighthQuestion)

		// ninthQuestion input
		setQuestionFiles('ninthInput', ninthQuestion, setNinthQuestion)

		// tenthQuestion input
		setQuestionFiles('tenthInput', tenthQuestion, setTenthQuestion)

		// eleventhQuestion input
		setQuestionFiles('eleventhInput', eleventhQuestion, setEleventhQuestion)

		// twelfthQuestion input
		setQuestionFiles('twelfthInput', twelfthQuestion, setTwelfthQuestion)
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		// setSteps &&
		// 	setSteps({
		// 		...StepsUrbanism
		// 	})

		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			details_5: true,
			details_6: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})

		setStepsList &&
			setStepsList([
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step1),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step2),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step3),
					subSteps: [
						pageAssets?.request_form_type_de_demandes,
						pageAssets?.request_form_request_location,
						pageAssets?.request_form_type_of_use,
						// Information on renovations and repairs
						pageAssets?.request_form_information_on_renovations,
						pageAssets?.request_form_information_on_the_work,
						pageAssets?.request_form_branching,
						pageAssets?.request_form_required_documents
					]
				},
				// {
				// 	title: formatStrapiText(
				// 		pageAssets?.page_requestInfo_step_complementary_requests
				// 	),
				// 	subSteps: [pageAssets?.page_requestInfo_step_complementary_requests]
				// },
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step4),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step5),
					subSteps: []
				}
			])
	}, [])

	const backToForm = (
		step: number,
		subStep: string,
		currentSubStep: number
	) => {
		if (setCurrentStep && onSetSubSteps && subSteps && onSetCurrentSubStep) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
			onSetCurrentSubStep(currentSubStep)
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer) {
			return (
				<div>
					<strong>{question}</strong>
					<br />
					<span className={pageUtils.classes.answerURB}>{answer}</span>
				</div>
			)
		}
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	useEffect(() => {
		{
			console.log('Errors -> ', errors)
			// console.table(inputFiles)
		}
	}, [errors])

	if (isSummaryStep) {
		return (
			<section
				className={joinClasses([
					pageUtils.classes.noMargin,
					pageUtils.classes.collapse,
					pageUtils.classes.summarySubTitles,
					toPrint ? pageUtils.classes.toPrintTitle : ''
				])}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<Collapse
							id={1}
							buttonText={pageAssets?.request_form_type_de_demandes}
							isOpen={toPrint}
							key={`request_form_type_de_demandes`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
												1
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_de_demandes}
									</h4>
								)}
							</div>

							{/* Question 1 */}
							<div>
								<strong>{whatToDoInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									<Text
										content={getLabelFromList(
											radioListWhatToDo,
											answers?.whatToDo
										)}
									/>
								</span>
							</div>

							{/* Question 2 */}
							<div>
								<strong>{requestAsInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListRequestAs, answers?.requestAs)}
								</span>
							</div>

							{[REQUEST_CONFIGS_KEYS.requestAs.entreprise].includes(
								answers?.requestAs?.toString() || ''
							) && (
								<>
									<div>
										<strong>{companyNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.companyName}
										</span>
									</div>
									<div>
										<strong>{representativeNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.representativeName}
										</span>
									</div>
								</>
							)}

							{(isRenovationExterior(answers?.whatToDo) ||
								isRenovationInteriorAndExterior(answers?.whatToDo)) && (
								<>
									{/* Question 3: Is Subject to PIIA Approval */}
									<div>
										<strong>{isSubjectToPiiaApprovalInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{getLabelFromList(
												radioListYesNo,
												answers?.isSubjectToPiiaApproval
											)}
										</span>
									</div>

									{/* Question 4: Has Been Subject to Approval */}
									{answers?.isSubjectToPiiaApproval?.toString() ==
										radioListYesNo[0].value && (
										<>
											<div>
												<strong>{hasBeenSubjectToApprovalInput.label}</strong>
												<br />
												<span className={pageUtils.classes.answerURB}>
													{getLabelFromList(
														radioListYesNo,
														answers?.hasBeenSubjectToApproval
													)}
												</span>
											</div>

											{answers?.hasBeenSubjectToApproval?.toString() ==
												radioListYesNo[0].value && (
												<>
													{/* Question 4.1: IA Number */}
													<div>
														<strong>{iaNumberInput.label}</strong>
														<br />
														<span className={pageUtils.classes.answerURB}>
															{answers?.iaNumber}
														</span>
													</div>

													{/* Question 4.2: EC Resolution Number */}
													{answers?.ecResolutionNumber && (
														<div>
															<strong>{ecResolutionNumberInput.label}</strong>
															<br />
															<span className={pageUtils.classes.answerURB}>
																{answers?.ecResolutionNumber}
															</span>
														</div>
													)}
												</>
											)}
										</>
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<Collapse
							id={2}
							buttonText={pageAssets?.request_form_request_location}
							isOpen={toPrint}
							key={`request_form_request_location`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
												2
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_request_location}
									</h4>
								)}
							</div>

							{/* Question 7 */}

							<div>
								<strong>{isSameLocationInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.buildingAddressSameHasApplicant
									)}
								</span>
							</div>

							{/* Question 8 */}
							{answers?.buildingAddressSameHasApplicant?.toString() ==
								radioListYesNo[1].value && (
								<>
									<div>
										<strong>{pageAssets?.request_form_location}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.addressField ? (
												<>
													{answers?.addressField}
													<br />
												</>
											) : (
												''
											)}

											{answers?.addressLotNumber
												? pageAssets?.label_cadastral_number +
												  ': ' +
												  answers?.addressLotNumber
												: ''}
										</span>
									</div>
									{(answers?.addressVoiePublic ||
										answers?.addressCivicNumber) && (
										<div>
											<strong>
												{
													pageAssets?.request_form_addressVoiePublicSection_public_road
												}
											</strong>
											<br />
											<span>
												{answers?.addressCivicNumber}
												{answers?.addressCivicNumber &&
													answers?.addressVoiePublic &&
													', '}
												{answers?.addressVoiePublic}
											</span>
										</div>
									)}
									{(answers?.addressVoiePublic2 ||
										answers?.addressCivicNumber2) && (
										<div>
											<span>
												{answers?.addressCivicNumber2}
												{answers?.addressCivicNumber2 &&
													answers?.addressVoiePublic2 &&
													', '}
												{answers?.addressVoiePublic2}
											</span>
										</div>
									)}

									{(answers?.addressVoiePublic3 ||
										answers?.addressCivicNumber3) && (
										<div>
											<span>
												{answers?.addressCivicNumber3}
												{answers?.addressCivicNumber3 &&
													answers?.addressVoiePublic3 &&
													', '}
												{answers?.addressVoiePublic3}
											</span>
										</div>
									)}

									{answers?.addressAdditionalInfos && (
										<div>
											<span>{answers?.addressAdditionalInfos}</span>
										</div>
									)}
									<br />
								</>
							)}

							{/* Question 9 */}
							<div>
								<strong>{isLocationOwnedInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListYesNo, answers?.isLocationOwned)}
								</span>
							</div>

							{/* ****** Owner inputs ****** */}
							{answers?.isLocationOwned?.toString() ==
								radioListYesNo[1].value && (
								<>
									{questionAnswer(
										ownerFirstNameInput.label,
										answers?.ownerFirstName
									)}
									{questionAnswer(
										ownerLastNameInput.label,
										answers?.ownerLastName
									)}
									{questionAnswer(ownerEmailInput.label, answers?.ownerEmail)}
									{questionAnswer(
										ownerPhoneNumberInput.label,
										answers?.ownerPhoneNumber
									)}

									<RequestFiles
										subtitle={firstQuestion.label}
										name={firstQuestion.name}
										required={firstQuestion.required}
										files={firstQuestion.files}
										hasError={errors.includes(firstQuestion.name)}
										removeSectionMarginTop
										removePadding
										equivalentMargin
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileFirstQuestion}
										maxFiles={firstQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
									<br />
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_3 && (
					<>
						<Collapse
							id={3}
							buttonText={pageAssets?.request_form_type_of_use}
							isOpen={toPrint}
							key={`request_form_type_of_use`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
												3
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_of_use}
									</h4>
								)}
							</div>

							<div>
								<>
									{/* Question 10 */}
									{questionAnswer(
										mainUseOfExistingBuildingInput.label,
										getLabelFromList(
											radioListMainUseOfExistingBuilding,
											answers?.mainUseOfExistingBuilding
										)
									)}

									{/* Question 12 */}
									{questionAnswer(
										isTheNewBuildingPartOfAPropertyInput.label,
										getLabelFromList(
											radioListYesNo,
											answers?.existingMainBuildingIsShared
										)
									)}
								</>
							</div>
						</Collapse>
					</>
				)}
				{subSteps?.details_4 && (
					<>
						<Collapse
							id={4}
							buttonText={
								pageAssets?.request_form_information_on_renovations_and_repairs
							}
							isOpen={toPrint}
							key={`request_form_building_informations`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
												4
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{
											pageAssets?.request_form_information_on_renovations_and_repairs
										}
									</h4>
								)}
							</div>

							{(isRenovationInteriorAndExterior(answers?.whatToDo) ||
								isRenovationExterior(answers?.whatToDo)) && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_information_on_exterior_renovations
										}
									</h5>

									{/* typesOfWorkExtInput */}
									<div>
										<strong>{typesOfWorkExtInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											<>
												{getLabelsFromString(
													radioListTypesOfWorkRenovationExterior,
													answers?.typesOfWorkExt
												).map((answerLabel, index) => {
													return (
														<p
															className={pageUtils.classes.answerURB}
															key={`${index}-typesOfWorkRenovationExterior`}
														>
															{parse(formatHtmlForStrapiText(answerLabel))}
														</p>
													)
												})}
											</>
										</span>
									</div>

									{(answers?.numberOfCurrentHousing?.toString() ||
										answers?.numberOfHousingAdded?.toString() ||
										answers?.wallWhereAddOpeningsOrReplaceDoors ||
										answers?.isThereOrWillThereBeOpenings?.toString() ||
										answers?.detailsOnReplacingHeatingSystemWithGeothermal?.toString() ||
										answers?.existingRoofingMaterial ||
										answers?.projectedRoofingMaterial ||
										answers?.existingExteriorCladdingMaterial ||
										answers?.projectedExteriorCladdingMaterial ||
										answers?.detailsOnRepairsToBeMadeToStructureOrFoundation ||
										answers?.pleaseSpecifyOtherWork) && (
										<h6 className={pageUtils.classes.h6}>
											{pageAssets?.request_form_additional_info}
										</h6>
									)}

									{[
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.firstOption,
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.fifthOption,
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.sixthOption
									].some((option) =>
										answers?.typesOfWorkExt?.includes(option)
									) && (
										<>
											{questionAnswer(
												numberOfCurrentHousingInput.label,
												answers?.numberOfCurrentHousing?.toString()
											)}

											{questionAnswer(
												numberOfHousingAddedInput.label,
												answers?.numberOfHousingAdded?.toString()
											)}
										</>
									)}

									{[
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.thirdOption,
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.eighthOption
									].some((option) =>
										answers?.typesOfWorkExt?.includes(option)
									) && (
										<>
											{answers?.wallWhereAddOpeningsOrReplaceDoors && (
												<div>
													<strong>
														{wallWhereAddOpeningsOrReplaceDoorsInput.label}
													</strong>
													<br />
													<span className={pageUtils.classes.answerURB}>
														<>
															{getLabelsFromString(
																checkboxesListWallWhereAddOpeningsOrReplaceDoors,
																answers?.wallWhereAddOpeningsOrReplaceDoors
															).map((answerLabel, index) => {
																return (
																	<p
																		className={pageUtils.classes.answerURB}
																		key={`${index}-wallWhereAddOpeningsOrReplaceDoors`}
																	>
																		{answerLabel}
																	</p>
																)
															})}
														</>
													</span>
												</div>
											)}

											{questionAnswer(
												isThereOrWillThereBeOpeningsInput.label,
												getLabelFromList(
													radioListYesNo,
													answers?.isThereOrWillThereBeOpenings?.toString() ||
														String(false)
												)
											)}
										</>
									)}

									{answers?.typesOfWorkExt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.seventhOption
									) && (
										<>
											{questionAnswer(
												detailsOnReplacingHeatingSystemWithGeothermalInput.label,
												answers?.detailsOnReplacingHeatingSystemWithGeothermal?.toString()
											)}
										</>
									)}

									{answers?.typesOfWorkExt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.ninthOption
									) && (
										<>
											{questionAnswer(
												existingRoofingMaterialInput.label,
												answers?.existingRoofingMaterial
											)}

											{questionAnswer(
												projectedRoofingMaterialInput.label,
												answers?.projectedRoofingMaterial
											)}
										</>
									)}

									{answers?.typesOfWorkExt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.tenthOption
									) && (
										<>
											{questionAnswer(
												existingExteriorCladdingMaterialInput.label,
												answers?.existingExteriorCladdingMaterial
											)}

											{questionAnswer(
												projectedExteriorCladdingMaterialInput.label,
												answers?.projectedExteriorCladdingMaterial
											)}
										</>
									)}

									{answers?.typesOfWorkExt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.thirteenthOption
									) && (
										<>
											{questionAnswer(
												detailsOnRepairsToBeMadeToStructureOrFoundationInput.label,
												answers?.detailsOnRepairsToBeMadeToStructureOrFoundation
											)}
										</>
									)}

									{answers?.typesOfWorkExt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
											.fourteenthOption
									) && (
										<>
											{questionAnswer(
												pleaseSpecifyOtherWorkInput.label,
												answers?.pleaseSpecifyOtherWork
											)}
										</>
									)}
								</>
							)}

							{(isRenovationInteriorAndExterior(answers?.whatToDo) ||
								isRenovationInterior(answers?.whatToDo)) && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_information_on_interior_renovations
										}
									</h5>

									<div>
										<strong>{typesOfWorkIntInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											<>
												{getLabelsFromString(
													radioListTypesOfWorkRenovationInterior,
													answers?.typesOfWorkInt
												).map((answerLabel, index) => {
													return (
														<p
															className={pageUtils.classes.answerURB}
															key={`${index}-typesOfWorkInt`}
														>
															{parse(formatHtmlForStrapiText(answerLabel))}
														</p>
													)
												})}
											</>
										</span>
									</div>

									{(answers?.numberOfCurrentHousingInt?.toString() ||
										answers?.numberOfHousingAddedInt?.toString() ||
										answers?.detailsInt ||
										answers?.bathroomLocation ||
										answers?.housingLocation ||
										answers?.pleaseSpecifyOtherWorkInt) && (
										<h6 className={pageUtils.classes.h6}>
											{pageAssets?.request_form_additional_info}
										</h6>
									)}

									{answers?.typesOfWorkInt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
											.thirdOption
									) && (
										<>
											{questionAnswer(
												numberOfCurrentHousingIntInput.label,
												answers?.numberOfCurrentHousingInt?.toString()
											)}
											{questionAnswer(
												numberOfHousingAddedIntInput.label,
												answers?.numberOfHousingAddedInt?.toString()
											)}
										</>
									)}

									{answers?.typesOfWorkInt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
											.fifthOption
									) && (
										<>
											{questionAnswer(
												detailsIntInput.label,
												answers?.detailsInt
											)}
										</>
									)}

									{answers?.typesOfWorkInt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
											.secondOption
									) && (
										<>
											{questionAnswer(
												bathroomLocationInput.label,
												answers?.bathroomLocation
											)}
										</>
									)}

									{answers?.typesOfWorkInt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
											.thirdOption
									) && (
										<>
											{questionAnswer(
												housingLocationInput.label,
												answers?.housingLocation
											)}
										</>
									)}

									{answers?.typesOfWorkInt?.includes(
										REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
											.seventhOption
									) && (
										<>
											{questionAnswer(
												pleaseSpecifyOtherWorkIntInput.label,
												answers?.pleaseSpecifyOtherWorkInt
											)}
										</>
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_5 && (
					<>
						<Collapse
							id={5}
							buttonText={pageAssets?.request_form_information_on_the_work}
							isOpen={toPrint}
							key={`request_form_information_on_the_work`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
												5
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}

								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_information_on_the_work}
									</h4>
								)}
							</div>

							{/* Question 24 */}
							<div>
								<strong>{Q24IsWorkDueToDisasterInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isWorkDueToDisaster
									)}
								</span>
							</div>

							{answers?.isWorkDueToDisaster?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div>
										<strong>
											{Q24_1WorkDueToDisasterPrecisionInput.label}
										</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.workDueToDisasterPrecision}
										</span>
									</div>
								</>
							)}

							{/* Question 25 */}
							<div>
								<strong>{Q25ApproximateCostOfWorkInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{formatNumberWithSpaces(
										answers?.approximateCostOfWork?.toString()
									)}{' '}
									$
								</span>
							</div>

							{/* Question 27 */}
							{questionAnswer(
								Q27IsTreeCuttingRequiredInput.label,
								getLabelFromList(radioListYesNo, answers?.isTreeCuttingRequired)
							)}

							{/* Question 28 */}
							{questionAnswer(
								Q28AdditionalInfoInput.label,
								answers?.additionalInfoQ28
							)}

							{/* Dates des travaux */}
							{(answers?.workStartDate || answers?.workEndDate) && (
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
									}
								</h5>
							)}

							{answers?.workStartDate && (
								<div>
									<strong>{Q29StartDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workStartDate!)}
									</span>
								</div>
							)}

							{answers?.workEndDate && (
								<div>
									<strong>{Q30EndDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workEndDate!)}
									</span>
								</div>
							)}

							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
								}
							</h5>

							{/* Question 31 */}
							{questionAnswer(
								Q31WhoWillBeInChargeOfWorkInput.label,
								getLabelFromList(
									radioListWhoWillBeInChargeOfWork,
									answers?.whoWillBeInChargeOfWork
								)
							)}

							{[
								radioListWhoWillBeInChargeOfWork[1].value,
								radioListWhoWillBeInChargeOfWork[2].value
							].includes(answers?.whoWillBeInChargeOfWork?.toString()!) &&
								questionAnswer(
									Q31_1EntrepreneurUnknownInput.label,
									getLabelFromList(
										radioListYesNo,
										answers?.entrepreneurUnknown1?.toString() || String(false)
									)
								)}

							{questionAnswer(
								Q32CompanyNameExecutingWorkInput.label,
								answers?.companyNameExecutingWork
							)}

							{questionAnswer(
								Q33CompanyNEQNumberInput.label,
								answers?.companyNEQNumber
							)}

							{questionAnswer(
								Q34CompanyRBQNumberInput.label,
								answers?.companyRBQNumber
							)}

							{(answers?.companyRepresentativeName ||
								answers?.companyRepresentativePhoneNumber ||
								answers?.companyRepresentativeEmail) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
									}
								</h6>
							)}

							{questionAnswer(
								Q35CompanyRepresentativeNameInput.label,
								answers?.companyRepresentativeName
							)}

							{questionAnswer(
								Q36CompanyRepresentativePhoneNumberInput.label,
								answers?.companyRepresentativePhoneNumber
							)}

							{questionAnswer(
								Q37CompanyRepresentativeEmailInput.label,
								answers?.companyRepresentativeEmail
							)}

							{(answers?.companyAddress ||
								answers?.companyCity ||
								answers?.companyPostalCode) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
									}
								</h6>
							)}

							{questionAnswer(
								Q38CompanyAddressInput.label,
								answers?.companyAddress
							)}

							{questionAnswer(Q39CompanyCityInput.label, answers?.companyCity)}

							{questionAnswer(
								Q40CompanyPostalCodeInput.label,
								answers?.companyPostalCode
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_6 && (
					<>
						<Collapse
							id={6}
							buttonText={pageAssets?.request_form_branching_aqueduc_egouts}
							isOpen={toPrint}
							key={`request_form_branching_aqueduc_egouts`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
												6
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_branching_aqueduc_egouts}
									</h4>
								)}
							</div>
							{questionAnswer(
								Q41IsWaterAndSewerConnectionRequiredInput.label,
								getLabelFromList(
									radioListYesNo,
									answers?.isWaterAndSewerConnectionRequired?.toString() ||
										String(false)
								)
							)}

							{answers?.isWaterAndSewerConnectionRequired?.toString() ==
								'true' && (
								<>
									{answers?.isPropertyConnectedToMunicipalConduits && (
										<div>
											<strong>
												{Q41IsPropertyConnectedToMunicipalConduitsInput.label}
											</strong>
											<br />
											<span className={pageUtils.classes.answerURB}>
												<>
													{getLabelsFromString(
														radioListIsPropertyConnectedToMunicipalConduits,
														answers?.isPropertyConnectedToMunicipalConduits
													).map((answerLabel, index) => {
														return (
															<p
																className={pageUtils.classes.answerURB}
																key={`${index}-isPropertyConnectedToMunicipalConduits`}
															>
																{answerLabel}
															</p>
														)
													})}
												</>
											</span>
										</div>
									)}
									{questionAnswer(
										Q42AdditionalInfoInput.label,
										answers?.additionalInfoQ42
									)}
								</>
							)}
							{answers?.isWaterAndSewerConnectionRequired?.toString() ==
								'true' && (
								<>
									{answers?.plannedWork && (
										<div>
											<strong>{Q43PlannedWorkInput.label}</strong>
											<br />
											<span className={pageUtils.classes.answerURB}>
												<>
													{getLabelsFromString(
														listPlannedWork,
														answers?.plannedWork
													).map((answerLabel, index) => {
														return (
															<p
																className={pageUtils.classes.answerURB}
																key={`${index}-plannedWork`}
															>
																{answerLabel}
															</p>
														)
													})}
												</>
											</span>
										</div>
									)}
									{questionAnswer(
										Q44AdditionalInfoInput.label,
										answers?.workTypeBuildingPrecision
									)}

									{isEntrepreneurFieldsRequired(answers?.plannedWork) && (
										<>
											<h5 className={pageUtils.classes.h5}>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
												}
											</h5>

											{questionAnswer(
												Q45EntrepreneurUnknownInput.label,
												getLabelFromList(
													radioListYesNo,
													answers?.entrepreneurUnknown?.toString() ||
														String(false)
												)
											)}

											{questionAnswer(
												Q46EntrepreneurNameInput.label,
												answers?.entrepreneurName
											)}

											{questionAnswer(
												Q47EntrepreneurRBQNumberInput.label,
												answers?.entrepreneurRBQNumber
											)}

											{(answers?.entrepreneurRepresentativeName ||
												answers?.entrepreneurRepresentativePhoneNumber ||
												answers?.entrepreneurRepresentativeEmail) && (
												<h6
													className={pageUtils.classes.h6}
													style={{ marginTop: '20px' }}
												>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
													}
												</h6>
											)}

											{questionAnswer(
												Q48EntrepreneurRepresentativeNameInput.label,
												answers?.entrepreneurRepresentativeName
											)}

											{questionAnswer(
												Q49EntrepreneurRepresentativePhoneNumberInput.label,
												answers?.entrepreneurRepresentativePhoneNumber
											)}

											{questionAnswer(
												Q50EntrepreneurRepresentativeEmailInput.label,
												answers?.entrepreneurRepresentativeEmail
											)}

											{(answers?.entrepreneurRepresentativeAddress ||
												answers?.entrepreneurRepresentativeCity ||
												answers?.entrepreneurRepresentativePostalCode) && (
												<h6
													className={pageUtils.classes.h6}
													style={{ marginTop: '20px' }}
												>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
													}
												</h6>
											)}

											{questionAnswer(
												Q51EntrepreneurRepresentativeAddressInput.label,
												answers?.entrepreneurRepresentativeAddress
											)}

											{questionAnswer(
												Q52EntrepreneurRepresentativeCityInput.label,
												answers?.entrepreneurRepresentativeCity
											)}

											{questionAnswer(
												Q53EntrepreneurRepresentativePostalCodeInput.label,
												answers?.entrepreneurRepresentativePostalCode
											)}
										</>
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<Collapse
							id={7}
							buttonText={pageAssets?.request_form_required_documents}
							isOpen={toPrint}
							key={`request_form_required_documents`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
												7
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_required_documents}
									</h4>
								)}
							</div>

							<>
								{/* Certificat de localisation */}
								{((answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
									answers?.hasBeenSubjectToApproval?.toString() == 'true') ||
									answers?.isSubjectToPiiaApproval?.toString() == 'false') &&
									(isRenovationExterior(answers?.whatToDo) ||
										isRenovationInteriorAndExterior(answers?.whatToDo)) && (
										<>
											<RequestFiles
												subtitle={thirdQuestion.label}
												name={thirdQuestion.name}
												required={thirdQuestion.required}
												files={thirdQuestion.files}
												hasError={errors.includes(thirdQuestion.name)}
												removeSectionMarginTop
												removePadding
												equivalentMargin
												onSetHasError={onSetErrorFromFileComponent}
												onSetFiles={onSelectFileThirdQuestion}
												maxFiles={thirdQuestion.numberFilesRequired}
												isSummary
											/>
										</>
									)}

								{((answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
									answers?.hasBeenSubjectToApproval?.toString() == 'true') ||
									answers?.isSubjectToPiiaApproval?.toString() == 'false') &&
									(isRenovationInterior(answers?.whatToDo) ||
										isRenovationInteriorAndExterior(answers?.whatToDo)) && (
										<>
											{/* Plan d'architecture  */}
											{fourthQuestion.files.size > 0 && (
												<RequestFiles
													subtitle={fourthQuestion.label}
													name={fourthQuestion.name}
													required={fourthQuestion.required}
													files={fourthQuestion.files}
													hasError={errors.includes(fourthQuestion.name)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectFileFourthQuestion}
													subSteps={subSteps}
													maxFiles={fourthQuestion?.numberMaxFiles}
													description={fourthQuestion.description}
													isSummary
												/>
											)}
											{/* Plan mécanique */}
											{fifthQuestion.files.size > 0 && (
												<RequestFiles
													subtitle={fifthQuestion.label}
													name={fifthQuestion.name}
													required={fifthQuestion.required}
													files={fifthQuestion.files}
													hasError={errors.includes(fifthQuestion.name)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectFileFifthQuestion}
													subSteps={subSteps}
													maxFiles={fifthQuestion?.numberMaxFiles}
													description={fifthQuestion.description}
													isSummary
												/>
											)}
											{/* Plan électrique */}
											{sixthQuestion.files.size > 0 && (
												<RequestFiles
													subtitle={sixthQuestion.label}
													name={sixthQuestion.name}
													required={sixthQuestion.required}
													files={sixthQuestion.files}
													hasError={errors.includes(sixthQuestion.name)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectFileSixthQuestion}
													subSteps={subSteps}
													maxFiles={sixthQuestion?.numberMaxFiles}
													description={sixthQuestion.description}
													isSummary
												/>
											)}
											{/* Plan ventilation */}
											{seventhQuestion.files.size > 0 && (
												<RequestFiles
													subtitle={seventhQuestion.label}
													name={seventhQuestion.name}
													required={seventhQuestion.required}
													files={seventhQuestion.files}
													hasError={errors.includes(seventhQuestion.name)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectFileSeventhQuestion}
													subSteps={subSteps}
													maxFiles={seventhQuestion?.numberMaxFiles}
													description={seventhQuestion.description}
													isSummary
												/>
											)}
											{/* Plan de structure */}
											{eighthQuestion.files.size > 0 && (
												<RequestFiles
													subtitle={eighthQuestion.label}
													name={eighthQuestion.name}
													required={eighthQuestion.required}
													files={eighthQuestion.files}
													hasError={errors.includes(eighthQuestion.name)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectFileEighthQuestion}
													subSteps={subSteps}
													maxFiles={eighthQuestion?.numberMaxFiles}
													description={eighthQuestion.description}
													isSummary
												/>
											)}
											{/* Fiche Branchement  */}
											{ninthQuestion.files.size > 0 && (
												<RequestFiles
													subtitle={ninthQuestion.label}
													name={ninthQuestion.name}
													required={ninthQuestion.required}
													files={ninthQuestion.files}
													hasError={errors.includes(ninthQuestion.name)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectFileNinthQuestion}
													subSteps={subSteps}
													maxFiles={ninthQuestion?.numberMaxFiles}
													description={ninthQuestion.description}
													isSummary
												/>
											)}
										</>
									)}

								{answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
									answers?.hasBeenSubjectToApproval?.toString() == 'false' && (
										<>
											{/* Plan d'architecture préliminaire  */}
											<RequestFiles
												subtitle={tenthQuestion.label}
												name={tenthQuestion.name}
												required={tenthQuestion.required}
												files={tenthQuestion.files}
												hasError={errors.includes(tenthQuestion.name)}
												removeSectionMarginTop
												onSetHasError={onSetErrorFromFileComponent}
												onSetFiles={onSelectFileTenthQuestion}
												subSteps={subSteps}
												maxFiles={tenthQuestion?.numberMaxFiles}
												description={tenthQuestion.description}
												isSummary
											/>
											{/* Fiche Bâtiment/Fiche Technique */}
											{eleventhQuestion.files.size > 0 && (
												<RequestFiles
													subtitle={eleventhQuestion.label}
													name={eleventhQuestion.name}
													required={eleventhQuestion.required}
													files={eleventhQuestion.files}
													hasError={errors.includes(eleventhQuestion.name)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectFileEleventhQuestion}
													subSteps={subSteps}
													maxFiles={eleventhQuestion?.numberMaxFiles}
													description={eleventhQuestion.description}
													isSummary
												/>
											)}
										</>
									)}

								{/* Autres documents */}
								{twelfthQuestion.files.size > 0 && (
									<RequestFiles
										subtitle={twelfthQuestion.label}
										name={twelfthQuestion.name}
										required={twelfthQuestion.required}
										files={twelfthQuestion.files}
										hasError={errors.includes(twelfthQuestion.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileTwelfthQuestion}
										subSteps={subSteps}
										maxFiles={twelfthQuestion?.numberMaxFiles}
										description={twelfthQuestion.description}
										isSummary
									/>
								)}
							</>
						</Collapse>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_de_demandes}
						</h4>

						{/* Question 1 */}
						<RadioInput
							name={whatToDoInput.name}
							label={whatToDoInput.label}
							value={whatToDoInput.value}
							list={radioListWhatToDo}
							direction={Direction.vertical}
							hasError={errors!.includes(whatToDoInput.name)}
							onChange={onSetWhatToDoInput}
							ref={whatToDoInput.ref as RefObject<HTMLInputElement>}
							required={whatToDoInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 2 */}
						<RadioInput
							name={requestAsInput.name}
							label={requestAsInput.label}
							value={requestAsInput.value}
							list={radioListRequestAs}
							direction={Direction.vertical}
							hasError={errors!.includes(requestAsInput.name)}
							onChange={onSetRequestAsInput}
							ref={requestAsInput.ref as RefObject<HTMLInputElement>}
							required={requestAsInput.required}
							hasBackgroundWrapper={true}
						/>

						{[REQUEST_CONFIGS_KEYS.requestAs.entreprise].includes(
							requestAsInput.value
						) && (
							<>
								{/* Question 2.1 */}
								<TextInput
									id={companyNameInput.name}
									label={companyNameInput.label}
									value={companyNameInput.value}
									onChange={onSetCompanyNameInput}
									hasError={errors?.includes(companyNameInput.name)}
									required={companyNameInput.required}
									ref={companyNameInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>

								{/* Question 2.2 */}
								<TextInput
									id={representativeNameInput.name}
									label={representativeNameInput.label}
									value={representativeNameInput.value}
									onChange={onSetRepresentativeNameInput}
									hasError={errors?.includes(representativeNameInput.name)}
									required={representativeNameInput.required}
									ref={
										representativeNameInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						{(isRenovationInteriorAndExterior(whatToDoInput.value) ||
							isRenovationExterior(whatToDoInput.value)) && (
							<>
								{/* Architectural implementation and integration plan (PIIA) */}
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_piia_title
									}
								</h5>

								{/* Question 3 */}
								<RadioInput
									name={isSubjectToPiiaApprovalInput.name}
									label={isSubjectToPiiaApprovalInput.label}
									value={isSubjectToPiiaApprovalInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(isSubjectToPiiaApprovalInput.name)}
									onChange={onSetIsSubjectToPiiaApprovalInput}
									ref={
										isSubjectToPiiaApprovalInput.ref as RefObject<HTMLInputElement>
									}
									required={isSubjectToPiiaApprovalInput.required}
									hasBackgroundWrapper={true}
									classWrapper={classes.reducedMarginBottom}
									description={
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description
									}
								/>

								{isSubjectToPiiaApprovalInput.value ==
									radioListYesNo[0].value && (
									<>
										{/* Question 4 */}
										<RadioInput
											name={hasBeenSubjectToApprovalInput.name}
											label={hasBeenSubjectToApprovalInput.label}
											value={hasBeenSubjectToApprovalInput.value}
											list={radioListYesNo}
											direction={Direction.vertical}
											hasError={errors!.includes(
												hasBeenSubjectToApprovalInput.name
											)}
											onChange={onSetHasBeenSubjectToApprovalInput}
											ref={
												hasBeenSubjectToApprovalInput.ref as RefObject<HTMLInputElement>
											}
											required={hasBeenSubjectToApprovalInput.required}
											hasBackgroundWrapper={true}
											classWrapper={classes.reducedMarginBottom}
										/>
										{hasBeenSubjectToApprovalInput.value ==
											radioListYesNo[0].value && (
											<>
												<MaskedTextInput
													id={iaNumberInput.name}
													label={iaNumberInput.label}
													value={iaNumberInput.value}
													onChange={onSetIaNumberInput}
													hasError={errors.includes(iaNumberInput.name)}
													required={iaNumberInput.required}
													ref={iaNumberInput.ref as RefObject<HTMLInputElement>}
													max={12}
													min={12}
													mask="IA-0000-0000"
													placeholder="IA-AAAA-XXXX"
													errors={errors}
													setError={onFixError}
													schema={/^IA-\d{4}-\d{4}$/}
												/>

												<MaskedTextInput
													id={ecResolutionNumberInput.name}
													label={ecResolutionNumberInput.label}
													value={ecResolutionNumberInput.value}
													onChange={onSetEcResolutionNumberInput}
													hasError={errors.includes(
														ecResolutionNumberInput.name
													)}
													required={ecResolutionNumberInput.required}
													ref={
														ecResolutionNumberInput.ref as RefObject<HTMLInputElement>
													}
													max={16}
													min={16}
													mask="CE-00000000-0000"
													placeholder="CE-AAAAMMJJ-XXXX"
													errors={errors}
													setError={onFixError}
													schema={/^CE-\d{8}-\d{4}$/}
												/>
											</>
										)}
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_request_location}
						</h4>

						<div className={classes.adresseBlock}>
							<div className={pageUtils.classes.fieldsContainer}>
								<div>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>

								{buildingBatchNumberInput.value && (
									<div>
										<strong>{pageAssets?.lot_number_label}</strong>
										<br />
										{buildingBatchNumberInput.value}
									</div>
								)}
							</div>
						</div>

						{/* Question 7 */}

						<RadioInput
							name={isSameLocationInput.name}
							label={isSameLocationInput.label}
							value={isSameLocationInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSameLocationInput.name)}
							onChange={onSetIsSameLocationInput}
							ref={isSameLocationInput.ref as RefObject<HTMLInputElement>}
							required={isSameLocationInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* Question 8 */}
						{showAdresseBlock == true && (
							<>
								<AddressVoiePublicSection
									label={pageAssets?.request_form_location}
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										isSameLocationInput.value == radioListYesNo[1].value
									}
									showButtonUserLocation={false}
									displayAddressDetails={true}
									requiredFields={[
										'addressOption',
										'addressField',
										'addressLotNumber'
									]}
									subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2}
									addressOptions={radioListAddressOption}
								/>

								<br />
							</>
						)}

						{isSameLocationInput.value == radioListYesNo[0].value &&
							isAddressOutsideLaval && (
								<Notification
									text={
										pageAssets?.request_form_isAddressOutsideLavalMessageShow_notification_label
									}
									type="warning"
									hasHtml
								/>
							)}

						{/* Question 9 */}
						<RadioInput
							name={isLocationOwnedInput.name}
							label={isLocationOwnedInput.label}
							value={isLocationOwnedInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isLocationOwnedInput.name)}
							onChange={onSetIsLocationOwnedInput}
							ref={isLocationOwnedInput.ref as RefObject<HTMLInputElement>}
							required={isLocationOwnedInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* ****** Owner inputs ****** */}
						{isLocationOwnedInput.value == radioListYesNo[1].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_ownerPowerOfAttorney_notification
									}
									type="info"
									hasHtml
								/>
								<div
									className={joinClasses([
										pageUtils.classes.fieldsContainer,
										classes.greyBackgroundBlock
									])}
								>
									<TextInput
										id={ownerFirstNameInput.name}
										label={ownerFirstNameInput.label}
										value={ownerFirstNameInput.value}
										onChange={onSetOwnerFirstNameInput}
										hasError={errors.includes(ownerFirstNameInput.name)}
										required={ownerFirstNameInput.required}
										ref={ownerFirstNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>
									<TextInput
										id={ownerLastNameInput.name}
										label={ownerLastNameInput.label}
										value={ownerLastNameInput.value}
										onChange={onSetOwnerLastNameInput}
										hasError={errors.includes(ownerLastNameInput.name)}
										required={ownerLastNameInput.required}
										ref={ownerLastNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>

									<TextInput
										id={ownerEmailInput.name}
										label={ownerEmailInput.label}
										value={ownerEmailInput.value}
										onChange={onSetOwnerEmailInput}
										hasError={errors?.includes(ownerEmailInput.name)}
										type="email"
										onSetError={onFixError}
										schema={formUtils.MASKS.email}
										schemaErrorText={pageAssets?.label_emailErrorText}
										required={ownerEmailInput.required}
										ref={ownerEmailInput.ref as RefObject<HTMLInputElement>}
										maxCharacters={100}
									/>
									<MaskedTextInput
										id={ownerPhoneNumberInput.name}
										label={ownerPhoneNumberInput.label}
										value={ownerPhoneNumberInput.value}
										onChange={onSetOwnerPhoneNumberInput}
										hasError={errors?.includes(ownerPhoneNumberInput.name)}
										type="tel"
										min={10}
										max={14}
										mask={formUtils.MASKS.phoneNumber}
										placeholder={formUtils.MASKS.phoneNumber}
										errors={errors ? errors : []}
										setError={onFixError}
										schema={PHONE_NUMBER_REGEX}
										required={ownerPhoneNumberInput.required}
										ref={
											ownerPhoneNumberInput.ref as RefObject<HTMLInputElement>
										}
									/>
								</div>

								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									subSteps={subSteps}
									maxFiles={firstQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_of_use}
						</h4>

						<RadioInput
							name={mainUseOfExistingBuildingInput.name}
							label={mainUseOfExistingBuildingInput.label}
							value={mainUseOfExistingBuildingInput.value}
							list={radioListMainUseOfExistingBuilding}
							direction={Direction.vertical}
							hasError={errors!.includes(mainUseOfExistingBuildingInput.name)}
							onChange={onSetMainUseOfExistingBuildingInput}
							ref={
								mainUseOfExistingBuildingInput.ref as RefObject<HTMLInputElement>
							}
							required={mainUseOfExistingBuildingInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 12 */}
						<RadioInput
							name={isTheNewBuildingPartOfAPropertyInput.name}
							label={isTheNewBuildingPartOfAPropertyInput.label}
							value={isTheNewBuildingPartOfAPropertyInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isTheNewBuildingPartOfAPropertyInput.name
							)}
							onChange={onSetIsTheNewBuildingPartOfAPropertyInput}
							ref={
								isTheNewBuildingPartOfAPropertyInput.ref as RefObject<HTMLInputElement>
							}
							required={isTheNewBuildingPartOfAPropertyInput.required}
							hasBackgroundWrapper={true}
						/>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_information_on_renovations_and_repairs}
						</h4>
						{(isRenovationInteriorAndExterior(whatToDoInput.value) ||
							isRenovationExterior(whatToDoInput.value)) && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.request_form_information_on_exterior_renovations}
								</h5>

								<CheckboxInput
									name={typesOfWorkExtInput.name}
									label={typesOfWorkExtInput.label}
									description={typesOfWorkExtInput.description}
									value={`${typesOfWorkExtInput.value}`}
									list={radioListTypesOfWorkRenovationExterior}
									onChange={onSetTypesOfWorkExtInput}
									required={typesOfWorkExtInput.required}
									hasError={errors.includes(typesOfWorkExtInput.name)}
									ref={typesOfWorkExtInput.ref as RefObject<HTMLInputElement>}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
								/>

								{[
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.firstOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.secondOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.thirdOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.fifthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.sixthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.seventhOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.eighthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.ninthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.tenthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.thirteenthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.fourteenthOption
								].some((option) =>
									typesOfWorkExtInput.value?.includes(option)
								) && (
									<h6 className={pageUtils.classes.h6}>
										{pageAssets?.request_form_additional_info}
									</h6>
								)}

								{[
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.firstOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.sixthOption
								].some((option) =>
									typesOfWorkExtInput.value?.includes(option)
								) && (
									<>
										<Notification
											text={
												pageAssets?.request_form_typesOfWorkRenovationExterior_notification_1
											}
											type="info"
											hasHtml
										/>
									</>
								)}

								{[
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.secondOption
								].some((option) =>
									typesOfWorkExtInput.value?.includes(option)
								) && (
									<>
										<Notification
											text={
												pageAssets?.request_form_typesOfWorkRenovationExterior_notification_2
											}
											type="info"
											hasHtml
										/>
									</>
								)}

								{[
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.firstOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.fifthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.sixthOption
								].some((option) =>
									typesOfWorkExtInput.value?.includes(option)
								) && (
									<>
										{/* Q10.1 numberOfCurrentHousingInput */}
										<TextInput
											id={numberOfCurrentHousingInput.name}
											label={numberOfCurrentHousingInput.label}
											value={numberOfCurrentHousingInput.value}
											onChange={onSetNumberOfCurrentHousingInput}
											hasError={errors.includes(
												numberOfCurrentHousingInput.name
											)}
											required={numberOfCurrentHousingInput.required}
											ref={
												numberOfCurrentHousingInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={6}
											type="number"
											onKeyPress={pageUtils.onKeyPressIntegerInput}
											min="0"
										/>
										{/* Q10.2 numberOfHousingAddedInput */}
										<TextInput
											id={numberOfHousingAddedInput.name}
											label={numberOfHousingAddedInput.label}
											value={numberOfHousingAddedInput.value}
											onChange={onSetNumberOfHousingAddedInput}
											hasError={errors.includes(numberOfHousingAddedInput.name)}
											required={numberOfHousingAddedInput.required}
											ref={
												numberOfHousingAddedInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={6}
											type="number"
											onKeyPress={pageUtils.onKeyPressIntegerInput}
											min="0"
										/>
									</>
								)}

								{[
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.thirdOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.eighthOption
								].some((option) =>
									typesOfWorkExtInput.value?.includes(option)
								) && (
									<>
										{/* Q10.3 wallWhereAddOpeningsOrReplaceDoorsInput */}
										<CheckboxInput
											name={wallWhereAddOpeningsOrReplaceDoorsInput.name}
											label={wallWhereAddOpeningsOrReplaceDoorsInput.label}
											description={
												wallWhereAddOpeningsOrReplaceDoorsInput.description
											}
											value={`${wallWhereAddOpeningsOrReplaceDoorsInput.value}`}
											list={checkboxesListWallWhereAddOpeningsOrReplaceDoors}
											onChange={onSetWallWhereAddOpeningsOrReplaceDoorsInput}
											required={
												wallWhereAddOpeningsOrReplaceDoorsInput.required
											}
											hasError={errors.includes(
												wallWhereAddOpeningsOrReplaceDoorsInput.name
											)}
											ref={
												wallWhereAddOpeningsOrReplaceDoorsInput.ref as RefObject<HTMLInputElement>
											}
											errors={errors}
											shouldNotDivide
											hasBackgroundWrapper
											onFixError={onFixError}
											newCheckValue
										/>
										{/* Q10.4 isThereOrWillThereBeOpeningsInput */}
										<RadioInput
											name={isThereOrWillThereBeOpeningsInput.name}
											label={isThereOrWillThereBeOpeningsInput.label}
											value={isThereOrWillThereBeOpeningsInput.value}
											list={radioListYesNo}
											direction={Direction.vertical}
											hasError={errors!.includes(
												isThereOrWillThereBeOpeningsInput.name
											)}
											onChange={onSetIsThereOrWillThereBeOpeningsInput}
											ref={
												isThereOrWillThereBeOpeningsInput.ref as RefObject<HTMLInputElement>
											}
											required={isThereOrWillThereBeOpeningsInput.required}
											hasBackgroundWrapper={true}
										/>
									</>
								)}

								{typesOfWorkExtInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.seventhOption
								) && (
									<>
										{/* Q10.5 detailsOnReplacingHeatingSystemWithGeothermalInput */}
										<TextInput
											id={
												detailsOnReplacingHeatingSystemWithGeothermalInput.name
											}
											label={
												detailsOnReplacingHeatingSystemWithGeothermalInput.label
											}
											value={
												detailsOnReplacingHeatingSystemWithGeothermalInput.value
											}
											onChange={
												onSetDetailsOnReplacingHeatingSystemWithGeothermalInput
											}
											hasError={errors.includes(
												detailsOnReplacingHeatingSystemWithGeothermalInput.name
											)}
											required={
												detailsOnReplacingHeatingSystemWithGeothermalInput.required
											}
											ref={
												detailsOnReplacingHeatingSystemWithGeothermalInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}

								{typesOfWorkExtInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.ninthOption
								) && (
									<>
										{/* Q10.6 existingRoofingMaterialInput */}
										<TextInput
											id={existingRoofingMaterialInput.name}
											label={existingRoofingMaterialInput.label}
											value={existingRoofingMaterialInput.value}
											onChange={onSetExistingRoofingMaterialInput}
											hasError={errors.includes(
												existingRoofingMaterialInput.name
											)}
											required={existingRoofingMaterialInput.required}
											ref={
												existingRoofingMaterialInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>

										{/* Q10.7 projectedRoofingMaterialInput */}
										<TextInput
											id={projectedRoofingMaterialInput.name}
											label={projectedRoofingMaterialInput.label}
											value={projectedRoofingMaterialInput.value}
											onChange={onSetProjectedRoofingMaterialInput}
											hasError={errors.includes(
												projectedRoofingMaterialInput.name
											)}
											required={projectedRoofingMaterialInput.required}
											ref={
												projectedRoofingMaterialInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}
								{typesOfWorkExtInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior.tenthOption
								) && (
									<>
										{/* Q10.8 existingExteriorCladdingMaterialInput */}
										<TextInput
											id={existingExteriorCladdingMaterialInput.name}
											label={existingExteriorCladdingMaterialInput.label}
											value={existingExteriorCladdingMaterialInput.value}
											onChange={onSetExistingExteriorCladdingMaterialInput}
											hasError={errors.includes(
												existingExteriorCladdingMaterialInput.name
											)}
											required={existingExteriorCladdingMaterialInput.required}
											ref={
												existingExteriorCladdingMaterialInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
										{/* Q10.9 projectedExteriorCladdingMaterialInput */}
										<TextInput
											id={projectedExteriorCladdingMaterialInput.name}
											label={projectedExteriorCladdingMaterialInput.label}
											value={projectedExteriorCladdingMaterialInput.value}
											onChange={onSetProjectedExteriorCladdingMaterialInput}
											hasError={errors.includes(
												projectedExteriorCladdingMaterialInput.name
											)}
											required={projectedExteriorCladdingMaterialInput.required}
											ref={
												projectedExteriorCladdingMaterialInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}
								{typesOfWorkExtInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.thirteenthOption
								) && (
									<>
										{/* Q10.10 detailsOnRepairsToBeMadeToStructureOrFoundationInput	 */}
										<TextInput
											id={
												detailsOnRepairsToBeMadeToStructureOrFoundationInput.name
											}
											label={
												detailsOnRepairsToBeMadeToStructureOrFoundationInput.label
											}
											value={
												detailsOnRepairsToBeMadeToStructureOrFoundationInput.value
											}
											onChange={
												onSetDetailsOnRepairsToBeMadeToStructureOrFoundationInput
											}
											hasError={errors.includes(
												detailsOnRepairsToBeMadeToStructureOrFoundationInput.name
											)}
											required={
												detailsOnRepairsToBeMadeToStructureOrFoundationInput.required
											}
											ref={
												detailsOnRepairsToBeMadeToStructureOrFoundationInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}
								{typesOfWorkExtInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationExterior
										.fourteenthOption
								) && (
									<>
										{/* Q10.11 pleaseSpecifyOtherWorkInput */}
										<TextInput
											id={pleaseSpecifyOtherWorkInput.name}
											label={pleaseSpecifyOtherWorkInput.label}
											value={pleaseSpecifyOtherWorkInput.value}
											onChange={onSetPleaseSpecifyOtherWorkInput}
											hasError={errors.includes(
												pleaseSpecifyOtherWorkInput.name
											)}
											required={pleaseSpecifyOtherWorkInput.required}
											ref={
												pleaseSpecifyOtherWorkInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}
							</>
						)}

						{(isRenovationInterior(whatToDoInput.value) ||
							isRenovationInteriorAndExterior(whatToDoInput.value)) && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.request_form_information_on_interior_renovations}
								</h5>

								<CheckboxInput
									name={typesOfWorkIntInput.name}
									label={typesOfWorkIntInput.label}
									description={typesOfWorkIntInput.description}
									value={`${typesOfWorkIntInput.value}`}
									list={radioListTypesOfWorkRenovationInterior}
									onChange={onSetTypesOfWorkIntInput}
									required={typesOfWorkIntInput.required}
									hasError={errors.includes(typesOfWorkIntInput.name)}
									ref={typesOfWorkIntInput.ref as RefObject<HTMLInputElement>}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
								/>

								{[
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.firstOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.secondOption,

									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.thirdOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.fifthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.sixthOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.seventhOption
								].some((option) =>
									typesOfWorkIntInput.value?.includes(option)
								) && (
									<h6 className={pageUtils.classes.h6}>
										{pageAssets?.request_form_additional_info}
									</h6>
								)}

								{[
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.thirdOption,
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.sixthOption
								].some((option) =>
									typesOfWorkIntInput.value?.includes(option)
								) && (
									<>
										<Notification
											text={
												pageAssets?.request_form_typesOfWorkRenovationExterior_notification_1
											}
											type="info"
											hasHtml
										/>
									</>
								)}

								{typesOfWorkIntInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.firstOption
								) && (
									<Notification
										text={
											pageAssets?.request_form_typesOfWorkRenovationExterior_notification_2
										}
										type="info"
										hasHtml
									/>
								)}

								{typesOfWorkIntInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.thirdOption
								) && (
									<>
										{/* numberOfCurrentHousingIntInput */}
										<TextInput
											id={numberOfCurrentHousingIntInput.name}
											label={numberOfCurrentHousingIntInput.label}
											value={numberOfCurrentHousingIntInput.value}
											onChange={onSetNumberOfCurrentHousingIntInput}
											hasError={errors.includes(
												numberOfCurrentHousingIntInput.name
											)}
											required={numberOfCurrentHousingIntInput.required}
											ref={
												numberOfCurrentHousingIntInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={6}
											type="number"
											onKeyPress={pageUtils.onKeyPressIntegerInput}
											min="0"
										/>

										{/* numberOfHousingAddedIntInput */}
										<TextInput
											id={numberOfHousingAddedIntInput.name}
											label={numberOfHousingAddedIntInput.label}
											value={numberOfHousingAddedIntInput.value}
											onChange={onSetNumberOfHousingAddedIntInput}
											hasError={errors.includes(
												numberOfHousingAddedIntInput.name
											)}
											required={numberOfHousingAddedIntInput.required}
											ref={
												numberOfHousingAddedIntInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={6}
											type="number"
											onKeyPress={pageUtils.onKeyPressIntegerInput}
											min="0"
										/>
									</>
								)}

								{typesOfWorkIntInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.thirdOption
								) && (
									<>
										{/* housingLocationInput */}
										<TextInput
											id={housingLocationInput.name}
											label={housingLocationInput.label}
											value={housingLocationInput.value}
											onChange={onSetHousingLocationInput}
											hasError={errors.includes(housingLocationInput.name)}
											required={housingLocationInput.required}
											ref={
												housingLocationInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}

								{typesOfWorkIntInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior.fifthOption
								) && (
									<>
										{/* detailsIntInput */}
										<TextInput
											id={detailsIntInput.name}
											label={detailsIntInput.label}
											value={detailsIntInput.value}
											onChange={onSetDetailsIntInput}
											hasError={errors.includes(detailsIntInput.name)}
											required={detailsIntInput.required}
											ref={detailsIntInput.ref as RefObject<HTMLInputElement>}
											maxCharacters={100}
										/>
									</>
								)}

								{typesOfWorkIntInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.secondOption
								) && (
									<>
										{/* bathroomLocationInput */}
										<TextInput
											id={bathroomLocationInput.name}
											label={bathroomLocationInput.label}
											value={bathroomLocationInput.value}
											onChange={onSetBathroomLocationInput}
											hasError={errors.includes(bathroomLocationInput.name)}
											required={bathroomLocationInput.required}
											ref={
												bathroomLocationInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}

								{typesOfWorkIntInput.value?.includes(
									REQUEST_CONFIGS_KEYS.typesOfWorkRenovationInterior
										.seventhOption
								) && (
									<>
										{/* pleaseSpecifyOtherWorkIntInput */}
										<TextInput
											id={pleaseSpecifyOtherWorkIntInput.name}
											label={pleaseSpecifyOtherWorkIntInput.label}
											value={pleaseSpecifyOtherWorkIntInput.value}
											onChange={onSetPleaseSpecifyOtherWorkIntInput}
											hasError={errors.includes(
												pleaseSpecifyOtherWorkIntInput.name
											)}
											required={pleaseSpecifyOtherWorkIntInput.required}
											ref={
												pleaseSpecifyOtherWorkIntInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={100}
										/>
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_information_on_the_work}
						</h4>

						<RadioInput
							name={Q24IsWorkDueToDisasterInput.name}
							label={Q24IsWorkDueToDisasterInput.label}
							value={Q24IsWorkDueToDisasterInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q24IsWorkDueToDisasterInput.name)}
							required={Q24IsWorkDueToDisasterInput.required}
							ref={
								Q24IsWorkDueToDisasterInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ24IsWorkDueToDisasterInput}
							classWrapper={classes.reducedMarginBottom}
						/>

						{Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value && (
							<>
								<TextInput
									id={Q24_1WorkDueToDisasterPrecisionInput.name}
									label={Q24_1WorkDueToDisasterPrecisionInput.label}
									value={Q24_1WorkDueToDisasterPrecisionInput.value}
									onChange={onSetQ24_1WorkDueToDisasterPrecisionInput}
									hasError={errors!.includes(
										Q24_1WorkDueToDisasterPrecisionInput.name
									)}
									required={Q24_1WorkDueToDisasterPrecisionInput.required}
									ref={
										Q24_1WorkDueToDisasterPrecisionInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						<MaskedThousandsSeparatorInput
							id={Q25ApproximateCostOfWorkInput.name}
							label={Q25ApproximateCostOfWorkInput.label}
							value={Q25ApproximateCostOfWorkInput.value}
							onChange={onSetQ25ApproximateCostOfWorkInput}
							hasError={errors.includes(Q25ApproximateCostOfWorkInput.name)}
							required={Q25ApproximateCostOfWorkInput.required}
							ref={
								Q25ApproximateCostOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							errors={errors}
							setError={onFixError}
							displayCurrency
						/>

						<RadioInput
							name={Q27IsTreeCuttingRequiredInput.name}
							label={Q27IsTreeCuttingRequiredInput.label}
							value={Q27IsTreeCuttingRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q27IsTreeCuttingRequiredInput.name)}
							required={Q27IsTreeCuttingRequiredInput.required}
							ref={
								Q27IsTreeCuttingRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ27IsTreeCuttingRequiredInput}
							description={Q27IsTreeCuttingRequiredInput.description}
						/>

						<TextAreaInput
							id={Q28AdditionalInfoInput.name}
							label={Q28AdditionalInfoInput.label}
							value={Q28AdditionalInfoInput.value}
							onChange={onSetQ28AdditionalInfoInput}
							hasError={errors!.includes(Q28AdditionalInfoInput.name)}
							required={Q28AdditionalInfoInput.required}
							ref={Q28AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={100}
							hasDescriptionLabel={Q28AdditionalInfoInput.description}
							displayDescriptionTop
							descriptionAlignment="left"
							rows={4}
						/>

						{/* Dates des travaux */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
							}
						</h5>

						<div className={pageUtils.classes.fieldsContainer}>
							<DateFilterInput
								id={Q29StartDateInput.name}
								label={Q29StartDateInput.label}
								value={Q29StartDateInput.value}
								onChange={onSetQ29StartDateInput}
								hasError={errors!.includes(Q29StartDateInput.name)}
								required={Q29StartDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date()}
							/>

							<DateFilterInput
								id={Q30EndDateInput.name}
								label={Q30EndDateInput.label}
								value={Q30EndDateInput.value}
								onChange={onSetQ30EndDateInput}
								hasError={errors!.includes(Q30EndDateInput.name)}
								required={Q30EndDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date(Q29StartDateInput.value + 'T12:00:00')}
								disabled={Q29StartDateInput.value == ''}
							/>
						</div>

						{/* Entrepreneur exécutant les travaux de construction. */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
							}
						</h5>

						<RadioInput
							name={Q31WhoWillBeInChargeOfWorkInput.name}
							label={Q31WhoWillBeInChargeOfWorkInput.label}
							value={Q31WhoWillBeInChargeOfWorkInput.value}
							list={
								requestAsInput.value ==
								radioListWhoWillBeInChargeOfWork[0].value
									? radioListWhoWillBeInChargeOfWork
									: radioListWhoWillBeInChargeOfWork.slice(1)
							}
							direction={Direction.vertical}
							hasError={errors!.includes(Q31WhoWillBeInChargeOfWorkInput.name)}
							required={Q31WhoWillBeInChargeOfWorkInput.required}
							ref={
								Q31WhoWillBeInChargeOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ31WhoWillBeInChargeOfWorkInput}
						/>

						{/* <br /> */}
						{[
							radioListWhoWillBeInChargeOfWork[1].value,
							radioListWhoWillBeInChargeOfWork[2].value
						].includes(Q31WhoWillBeInChargeOfWorkInput.value) && (
							<div className={classes.checkbox2Container}>
								<span
									className={classes.checkboxWrapper}
									style={{ marginTop: '10px' }}
								>
									<input
										onClick={(e: any) =>
											onSetQ31_1EntrepreneurUnknownInput(e.target.checked)
										}
										value={Q31_1EntrepreneurUnknownInput.value?.toString()}
										className={classes.checkbox2}
										checked={
											Q31_1EntrepreneurUnknownInput.value.toString() == 'true'
										}
										type="checkbox"
										name={Q31_1EntrepreneurUnknownInput.name}
										id={Q31_1EntrepreneurUnknownInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={Q31_1EntrepreneurUnknownInput.labelKey}
									>
										{Q31_1EntrepreneurUnknownInput.label}
									</label>
								</span>
							</div>
						)}

						<TextInput
							id={Q32CompanyNameExecutingWorkInput.name}
							label={Q32CompanyNameExecutingWorkInput.label}
							value={Q32CompanyNameExecutingWorkInput.value}
							onChange={onSetQ32CompanyNameExecutingWorkInput}
							hasError={errors!.includes(Q32CompanyNameExecutingWorkInput.name)}
							required={Q32CompanyNameExecutingWorkInput.required}
							ref={
								Q32CompanyNameExecutingWorkInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={50}
						/>
						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q33CompanyNEQNumberInput.name}
								label={Q33CompanyNEQNumberInput.label}
								value={Q33CompanyNEQNumberInput.value}
								onChange={onSetQ33CompanyNEQNumberInput}
								hasError={errors!.includes(Q33CompanyNEQNumberInput.name)}
								required={Q33CompanyNEQNumberInput.required}
								ref={
									Q33CompanyNEQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={20}
							/>

							<TextInput
								id={Q34CompanyRBQNumberInput.name}
								label={Q34CompanyRBQNumberInput.label}
								value={Q34CompanyRBQNumberInput.value}
								onChange={onSetQ34CompanyRBQNumberInput}
								hasError={errors!.includes(Q34CompanyRBQNumberInput.name)}
								required={Q34CompanyRBQNumberInput.required}
								ref={
									Q34CompanyRBQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={20}
							/>
						</div>

						{/* Représentant(e) ou personne contact */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
							}
						</h6>

						<TextInput
							id={Q35CompanyRepresentativeNameInput.name}
							label={Q35CompanyRepresentativeNameInput.label}
							value={Q35CompanyRepresentativeNameInput.value}
							onChange={onSetQ35CompanyRepresentativeNameInput}
							hasError={errors!.includes(
								Q35CompanyRepresentativeNameInput.name
							)}
							required={Q35CompanyRepresentativeNameInput.required}
							ref={
								Q35CompanyRepresentativeNameInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={100}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<MaskedTextInput
								id={Q36CompanyRepresentativePhoneNumberInput.name}
								label={Q36CompanyRepresentativePhoneNumberInput.label}
								value={Q36CompanyRepresentativePhoneNumberInput.value}
								onChange={onSetQ36CompanyRepresentativePhoneNumberInput}
								hasError={errors?.includes(
									Q36CompanyRepresentativePhoneNumberInput.name
								)}
								type="tel"
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={Q36CompanyRepresentativePhoneNumberInput.required}
								ref={
									Q36CompanyRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>

							<TextInput
								id={Q37CompanyRepresentativeEmailInput.name}
								label={Q37CompanyRepresentativeEmailInput.label}
								value={Q37CompanyRepresentativeEmailInput.value}
								onChange={onSetQ37CompanyRepresentativeEmailInput}
								hasError={errors!.includes(
									Q37CompanyRepresentativeEmailInput.name
								)}
								required={Q37CompanyRepresentativeEmailInput.required}
								ref={
									Q37CompanyRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={100}
								type="email"
								schema={formUtils.MASKS.email}
								schemaErrorText={pageAssets.label_emailErrorText}
								onSetError={onFixError}
							/>
						</div>

						{/* Adresse de l'entreprise */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
							}
						</h6>

						<TextInput
							id={Q38CompanyAddressInput.name}
							label={Q38CompanyAddressInput.label}
							value={Q38CompanyAddressInput.value}
							onChange={onSetQ38CompanyAddressInput}
							hasError={errors!.includes(Q38CompanyAddressInput.name)}
							required={Q38CompanyAddressInput.required}
							ref={Q38CompanyAddressInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={50}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q39CompanyCityInput.name}
								label={Q39CompanyCityInput.label}
								value={Q39CompanyCityInput.value}
								onChange={onSetQ39CompanyCityInput}
								hasError={errors!.includes(Q39CompanyCityInput.name)}
								required={Q39CompanyCityInput.required}
								ref={Q39CompanyCityInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={50}
							/>

							<MaskedTextInput
								id={Q40CompanyPostalCodeInput.name}
								label={Q40CompanyPostalCodeInput.label}
								value={Q40CompanyPostalCodeInput.value}
								onChange={onSetQ40CompanyPostalCodeInput}
								hasError={errors!.includes(Q40CompanyPostalCodeInput.name)}
								required={Q40CompanyPostalCodeInput.required}
								ref={
									Q40CompanyPostalCodeInput.ref as RefObject<HTMLInputElement>
								}
								max={7}
								min={7}
								mask={formUtils.MASKS.postalCode}
								placeholder="A1A 1A1"
								errors={errors}
								setError={onFixError}
								schema={POSTAL_CODE_REGEX}
							/>
						</div>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{/* Travaux de branchement d'aqueduc et d'égouts */}
							{pageAssets?.request_form_branching_aqueduc_egouts}
						</h4>

						<RadioInput
							name={Q41IsWaterAndSewerConnectionRequiredInput.name}
							label={Q41IsWaterAndSewerConnectionRequiredInput.label}
							value={Q41IsWaterAndSewerConnectionRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors.includes(
								Q41IsWaterAndSewerConnectionRequiredInput.name
							)}
							required={Q41IsWaterAndSewerConnectionRequiredInput.required}
							ref={
								Q41IsWaterAndSewerConnectionRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ41IsWaterAndSewerConnectionRequiredInput}
						/>

						{Q41IsWaterAndSewerConnectionRequiredInput?.value?.toString() ==
							radioListYesNo[0].value && (
							<>
								<CheckboxInput
									name={Q41IsPropertyConnectedToMunicipalConduitsInput.name}
									label={Q41IsPropertyConnectedToMunicipalConduitsInput.label}
									description={
										Q41IsPropertyConnectedToMunicipalConduitsInput.description
									}
									value={`${Q41IsPropertyConnectedToMunicipalConduitsInput.value}`}
									list={radioListIsPropertyConnectedToMunicipalConduits}
									onChange={onSetQ41IsPropertyConnectedToMunicipalConduitsInput}
									required={
										Q41IsPropertyConnectedToMunicipalConduitsInput.required
									}
									hasError={errors.includes(
										Q41IsPropertyConnectedToMunicipalConduitsInput.name
									)}
									ref={
										Q41IsPropertyConnectedToMunicipalConduitsInput.ref as RefObject<HTMLInputElement>
									}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
									disabledValues={disabledMunicipalConduits}
								/>

								<TextAreaInput
									id={Q42AdditionalInfoInput.name}
									label={Q42AdditionalInfoInput.label}
									value={Q42AdditionalInfoInput.value}
									onChange={onSetQ42AdditionalInfoInput}
									hasError={errors.includes(Q42AdditionalInfoInput.name)}
									required={Q42AdditionalInfoInput.required}
									ref={
										Q42AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>
									}
									maxCharacters={100}
									rows={4}
								/>

								<CheckboxInput
									name={Q43PlannedWorkInput.name}
									label={Q43PlannedWorkInput.label}
									description={Q43PlannedWorkInput.description}
									value={`${Q43PlannedWorkInput.value}`}
									list={listPlannedWork}
									onChange={onSetQ43PlannedWorkInput}
									required={Q43PlannedWorkInput.required}
									hasError={errors.includes(Q43PlannedWorkInput.name)}
									ref={Q43PlannedWorkInput.ref as RefObject<HTMLInputElement>}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
								/>

								{Q43PlannedWorkInput.value.includes(
									listPlannedWork.slice(-1)[0].value
								) && (
									<TextAreaInput
										id={Q44AdditionalInfoInput.name}
										label={Q44AdditionalInfoInput.label}
										value={Q44AdditionalInfoInput.value}
										onChange={onSetQ44AdditionalInfoInput}
										hasError={errors.includes(Q44AdditionalInfoInput.name)}
										required={Q44AdditionalInfoInput.required}
										ref={
											Q44AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>
										}
										maxCharacters={100}
										rows={4}
									/>
								)}

								{isEntrepreneurFieldsRequired(Q43PlannedWorkInput.value) && (
									<>
										{/* Entrepreneur (branchement d'aqueduc et d'égout ou génie civil) */}
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
											}
										</h5>

										<br />
										<div className={classes.checkbox2Container}>
											<span
												className={classes.checkboxWrapper}
												style={{ marginTop: '10px' }}
											>
												<input
													onClick={(e: any) =>
														onSetQ45EntrepreneurUnknownInput(e.target.checked)
													}
													value={Q45EntrepreneurUnknownInput.value?.toString()}
													className={classes.checkbox2}
													checked={
														Q45EntrepreneurUnknownInput.value.toString() ==
														'true'
													}
													type="checkbox"
													name={Q45EntrepreneurUnknownInput.name}
													id={Q45EntrepreneurUnknownInput.labelKey}
												/>
												<label
													className={classes.checkbox2Label}
													htmlFor={Q45EntrepreneurUnknownInput.labelKey}
												>
													{Q45EntrepreneurUnknownInput.label}
												</label>
											</span>
										</div>

										<TextInput
											id={Q46EntrepreneurNameInput.name}
											label={Q46EntrepreneurNameInput.label}
											value={Q46EntrepreneurNameInput.value}
											onChange={onSetQ46EntrepreneurNameInput}
											hasError={errors.includes(Q46EntrepreneurNameInput.name)}
											required={Q46EntrepreneurNameInput.required}
											ref={
												Q46EntrepreneurNameInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<TextInput
											id={Q47EntrepreneurRBQNumberInput.name}
											label={Q47EntrepreneurRBQNumberInput.label}
											value={Q47EntrepreneurRBQNumberInput.value}
											onChange={onSetQ47EntrepreneurRBQNumberInput}
											hasError={errors.includes(
												Q47EntrepreneurRBQNumberInput.name
											)}
											required={Q47EntrepreneurRBQNumberInput.required}
											ref={
												Q47EntrepreneurRBQNumberInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										{/* Représentant(e) ou personne contact. */}
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
											}
										</h6>

										<TextInput
											id={Q48EntrepreneurRepresentativeNameInput.name}
											label={Q48EntrepreneurRepresentativeNameInput.label}
											value={Q48EntrepreneurRepresentativeNameInput.value}
											onChange={onSetQ48EntrepreneurRepresentativeNameInput}
											hasError={errors.includes(
												Q48EntrepreneurRepresentativeNameInput.name
											)}
											required={Q48EntrepreneurRepresentativeNameInput.required}
											ref={
												Q48EntrepreneurRepresentativeNameInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<div className={pageUtils.classes.fieldsContainer}>
											<MaskedTextInput
												id={Q49EntrepreneurRepresentativePhoneNumberInput.name}
												label={
													Q49EntrepreneurRepresentativePhoneNumberInput.label
												}
												value={
													Q49EntrepreneurRepresentativePhoneNumberInput.value
												}
												onChange={
													onSetQ49EntrepreneurRepresentativePhoneNumberInput
												}
												hasError={errors?.includes(
													Q49EntrepreneurRepresentativePhoneNumberInput.name
												)}
												type="tel"
												min={10}
												max={14}
												mask={formUtils.MASKS.phoneNumber}
												placeholder={formUtils.MASKS.phoneNumber}
												errors={errors ? errors : []}
												setError={onFixError}
												schema={PHONE_NUMBER_REGEX}
												required={
													Q49EntrepreneurRepresentativePhoneNumberInput.required
												}
												ref={
													Q49EntrepreneurRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
												}
											/>

											<TextInput
												id={Q50EntrepreneurRepresentativeEmailInput.name}
												label={Q50EntrepreneurRepresentativeEmailInput.label}
												value={Q50EntrepreneurRepresentativeEmailInput.value}
												onChange={onSetQ50EntrepreneurRepresentativeEmailInput}
												hasError={errors.includes(
													Q50EntrepreneurRepresentativeEmailInput.name
												)}
												required={
													Q50EntrepreneurRepresentativeEmailInput.required
												}
												ref={
													Q50EntrepreneurRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
												}
												schema={formUtils.MASKS.email}
												schemaErrorText={pageAssets.label_emailErrorText}
												maxCharacters={50}
												type="email"
												onSetError={onFixError}
											/>
										</div>

										{/* Adresse de l'entreprise */}
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
											}
										</h6>

										<TextInput
											id={Q51EntrepreneurRepresentativeAddressInput.name}
											label={Q51EntrepreneurRepresentativeAddressInput.label}
											value={Q51EntrepreneurRepresentativeAddressInput.value}
											onChange={onSetQ51EntrepreneurRepresentativeAddressInput}
											hasError={errors.includes(
												Q51EntrepreneurRepresentativeAddressInput.name
											)}
											required={
												Q51EntrepreneurRepresentativeAddressInput.required
											}
											ref={
												Q51EntrepreneurRepresentativeAddressInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<div className={pageUtils.classes.fieldsContainer}>
											<TextInput
												id={Q52EntrepreneurRepresentativeCityInput.name}
												label={Q52EntrepreneurRepresentativeCityInput.label}
												value={Q52EntrepreneurRepresentativeCityInput.value}
												onChange={onSetQ52EntrepreneurRepresentativeCityInput}
												hasError={errors.includes(
													Q52EntrepreneurRepresentativeCityInput.name
												)}
												required={
													Q52EntrepreneurRepresentativeCityInput.required
												}
												ref={
													Q52EntrepreneurRepresentativeCityInput.ref as RefObject<HTMLInputElement>
												}
												maxCharacters={50}
											/>

											<MaskedTextInput
												id={Q53EntrepreneurRepresentativePostalCodeInput.name}
												label={
													Q53EntrepreneurRepresentativePostalCodeInput.label
												}
												value={
													Q53EntrepreneurRepresentativePostalCodeInput.value
												}
												onChange={
													onSetQ53EntrepreneurRepresentativePostalCodeInput
												}
												hasError={errors.includes(
													Q53EntrepreneurRepresentativePostalCodeInput.name
												)}
												required={
													Q53EntrepreneurRepresentativePostalCodeInput.required
												}
												ref={
													Q53EntrepreneurRepresentativePostalCodeInput.ref as RefObject<HTMLInputElement>
												}
												max={7}
												min={7}
												mask={formUtils.MASKS.postalCode}
												placeholder="A1A 1A1"
												errors={errors}
												setError={onFixError}
												schema={POSTAL_CODE_REGEX}
											/>
										</div>
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>

						<span className={pageUtils.classes.fileInfo}>
							{pageAssets?.page_requestInfo_inputFileDesc}
						</span>

						{/* Certificat de localisation */}
						{((isSubjectToPiiaApprovalInput.value == 'true' &&
							hasBeenSubjectToApprovalInput.value == 'true') ||
							isSubjectToPiiaApprovalInput.value == 'false') &&
							(isRenovationExterior(whatToDoInput.value) ||
								isRenovationInteriorAndExterior(whatToDoInput.value)) && (
								<>
									<RequestFiles
										subtitle={thirdQuestion.label}
										name={thirdQuestion.name}
										required={thirdQuestion.required}
										files={thirdQuestion.files}
										hasError={errors.includes(thirdQuestion.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileThirdQuestion}
										subSteps={subSteps}
										maxFiles={thirdQuestion?.numberMaxFiles}
										description={thirdQuestion.description}
									/>
								</>
							)}

						{(isRenovationInterior(whatToDoInput.value) ||
							(((isSubjectToPiiaApprovalInput.value == 'true' &&
								hasBeenSubjectToApprovalInput.value == 'true') ||
								isSubjectToPiiaApprovalInput.value == 'false') &&
								(isRenovationInterior(whatToDoInput.value) ||
									isRenovationInteriorAndExterior(whatToDoInput.value)))) && (
							<>
								{/* Plan d'architecture  */}
								<RequestFiles
									subtitle={fourthQuestion.label}
									name={fourthQuestion.name}
									required={fourthQuestion.required}
									files={fourthQuestion.files}
									hasError={errors.includes(fourthQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFourthQuestion}
									subSteps={subSteps}
									maxFiles={fourthQuestion?.numberMaxFiles}
									description={fourthQuestion.description}
								/>
								{/* Plan mécanique */}
								<RequestFiles
									subtitle={fifthQuestion.label}
									name={fifthQuestion.name}
									required={fifthQuestion.required}
									files={fifthQuestion.files}
									hasError={errors.includes(fifthQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFifthQuestion}
									subSteps={subSteps}
									maxFiles={fifthQuestion?.numberMaxFiles}
									description={fifthQuestion.description}
								/>
								{/* Plan électrique */}
								<RequestFiles
									subtitle={sixthQuestion.label}
									name={sixthQuestion.name}
									required={sixthQuestion.required}
									files={sixthQuestion.files}
									hasError={errors.includes(sixthQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileSixthQuestion}
									subSteps={subSteps}
									maxFiles={sixthQuestion?.numberMaxFiles}
									description={sixthQuestion.description}
								/>
								{/* Plan ventilation */}
								<RequestFiles
									subtitle={seventhQuestion.label}
									name={seventhQuestion.name}
									required={seventhQuestion.required}
									files={seventhQuestion.files}
									hasError={errors.includes(seventhQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileSeventhQuestion}
									subSteps={subSteps}
									maxFiles={seventhQuestion?.numberMaxFiles}
									description={seventhQuestion.description}
								/>
								{/* Plan de structure */}

								<RequestFiles
									subtitle={eighthQuestion.label}
									name={eighthQuestion.name}
									required={eighthQuestion.required}
									files={eighthQuestion.files}
									hasError={errors.includes(eighthQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileEighthQuestion}
									subSteps={subSteps}
									maxFiles={eighthQuestion?.numberMaxFiles}
									description={eighthQuestion.description}
								/>
								{/* Fiche Branchement  */}
								<RequestFiles
									subtitle={ninthQuestion.label}
									name={ninthQuestion.name}
									required={ninthQuestion.required}
									files={ninthQuestion.files}
									hasError={errors.includes(ninthQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileNinthQuestion}
									subSteps={subSteps}
									maxFiles={ninthQuestion?.numberMaxFiles}
									description={ninthQuestion.description}
								/>
							</>
						)}

						{isSubjectToPiiaApprovalInput.value == 'true' &&
							hasBeenSubjectToApprovalInput.value == 'false' && (
								<>
									{/* Plan d'architecture préliminaire  */}
									<RequestFiles
										subtitle={tenthQuestion.label}
										name={tenthQuestion.name}
										required={tenthQuestion.required}
										files={tenthQuestion.files}
										hasError={errors.includes(tenthQuestion.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileTenthQuestion}
										subSteps={subSteps}
										maxFiles={tenthQuestion?.numberMaxFiles}
										description={tenthQuestion.description}
									/>
									{/* Fiche Bâtiment/Fiche Technique */}
									<RequestFiles
										subtitle={eleventhQuestion.label}
										name={eleventhQuestion.name}
										required={eleventhQuestion.required}
										files={eleventhQuestion.files}
										hasError={errors.includes(eleventhQuestion.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileEleventhQuestion}
										subSteps={subSteps}
										maxFiles={eleventhQuestion?.numberMaxFiles}
										description={eleventhQuestion.description}
									/>
								</>
							)}

						{/* Autres documents */}
						<RequestFiles
							subtitle={twelfthQuestion.label}
							name={twelfthQuestion.name}
							required={twelfthQuestion.required}
							files={twelfthQuestion.files}
							hasError={errors.includes(twelfthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileTwelfthQuestion}
							subSteps={subSteps}
							maxFiles={twelfthQuestion?.numberMaxFiles}
							description={twelfthQuestion.description}
						/>
					</section>
				</ReactCollapse>
			)}
		</>
	)
}

export default ResidentialRenovationOrRepairPermitForm
